import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { BASE_URL, Country } from "../../constants"; // Assuming you have a list of countries in constants
import { toast } from "react-toastify";

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [validate, setValidate] = useState({});
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState({
    id: "new",
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
  });
  const [loading, setLoading] = useState(false);
  const freeEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
  ];

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleDropdownChange = (fieldName) => (selectedOption) => {
    console.log(selectedOption, "selecye");
    if (selectedOption !== undefined)
      setUser((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption,
      }));
  };
  const validateEmail = (email) => {
    const emailDomain = email.split("@")[1];
    if (freeEmailDomains.includes(emailDomain)) {
      setValidate((prev) => ({
        ...prev,
        email: "Please use your company email",
      }));
      return false;
    } else {
      setValidate((prev) => ({ ...prev, email: false }));
      return true;
    }
  };

  const onSignUpClick = async (e) => {
    e.preventDefault();
    console.log(user, "user");
    const newValidate = {};
    if (!user.email) newValidate.email = "Email is required";
    if (!user.firstName) newValidate.firstName = true;
    if (!user.lastName) newValidate.lastName = true;
    if (!user.companyName) newValidate.companyName = true;
    if (!user.country) newValidate.country = true;
    if (!validateEmail(user?.email))
      newValidate.email = "Please use your company email";

    setValidate(newValidate);

    if (Object.keys(newValidate).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post(`${BASE_URL}/api/Signup`, user);
        setLoading(false);
        setSuccess(true);
      } catch (err) {
        toast.error(err.response.data);
        setLoading(false);
        // Handle signup error
      }
    }
  };

  return (
    <div className="h-full bg-[rgb(4,9,31)]">
      <div className="flex relative">
        <div className="sm:w-1/2 lg:w-7/12 sm:block hidden relative">
          <img
            className="loginImg h-screen object-cover"
            src="loginPicture.jpg"
            alt="loginPicture"
          />
          <div className="absolute top-0 bg-black bg-opacity-50 h-full w-full">
            <div className="absolute top-1/4 lg:left-28 left-12 pr-5 text-white px-8 rounded-md py-6 flex flex-col gap-6">
              <label className="lg:text-4.5xl w-3/5 leading-10 text-4xl">
                {t("login_labels.signup_message")}
              </label>
            </div>
          </div>
        </div>
        {success ? (
          <div className="reveal  sm:w-1/2 lg:w-5/12 w-full px-10 md:px-16 sm:pt-24 pt-10 relative sm:mr-4">
            <div class="h-full">
              <div class="p-6  md:mx-auto">
                <svg
                  viewBox="0 0 24 24"
                  class="text-green-600 w-16 h-16 mx-auto my-6 "
                >
                  <path
                    fill="currentColor"
                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                  ></path>
                </svg>
                <div class="text-center text-gray-100">
                  <h3 class="md:text-2xl text-base text-green-500 font-semibold text-center">
                    {t("login_labels.signup_success")}
                  </h3>
                  <p class="text-gray-500 my-2">
                    {t("login_labels.thanks_message")}
                  </p>
                  <p>
                    {t("login_labels.activation_message")}
                    <span className="text-blue-700"> {user?.email}</span>
                  </p>
                  <p>{t("login_labels.help_message")}</p>
                  <a
                    className="text-blue-700"
                    href="mailto:info@gamberadynamics.com?subject=Registration Support"
                  >
                    info@gamberadynamics.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <form className="sm:w-1/2 lg:w-5/12 w-full px-10 md:px-16 sm:pt-24 pt-10 relative sm:mr-4">
            <label className="text-white sm:pt-0 text-xl font-medium">
              {t("login_labels.welcome")}
            </label>
            <div className="pt-7 flex flex-col gap-2 h-full">
              <label className="font-semibold text-white text-sm">
                {t("form_labels.email")}
              </label>
              <input
                type="email"
                name="email"
                placeholder={t("form_labels.email")}
                value={user.email}
                onBlur={() => validateEmail(user.email)}
                onChange={handleChange}
                className={`${
                  validate.email
                    ? "border-red-800 bg-red-550"
                    : "border-blue-800 bg-white"
                } w-full h-14 placeholder-neutral-400 pl-5 border text-xs font-medium rounded-xl focus:outline-none`}
              />
              {validate.email && (
                <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 28 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4482 7.10278V11.1028"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4482 15.1028H11.4582"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {validate.email === "Please use your company email"
                    ? "Please use your company email"
                    : t("form_labels.invalid_field_error")}
                </div>
              )}
              <div className="flex gap-2 justify-between">
                <div>
                  <label className="font-semibold text-white text-sm mt-1">
                    {t("form_labels.firstname")}
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder={t("form_labels.firstname")}
                    value={user.firstName}
                    onChange={handleChange}
                    className={`${
                      validate.firstName
                        ? "border-red-800 bg-red-550"
                        : "border-blue-800 bg-white"
                    } w-full h-14 placeholder-neutral-400 pl-5 border text-xs font-medium rounded-xl focus:outline-none`}
                  />
                  {validate.firstName && (
                    <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                      <svg
                        width="15"
                        height="12"
                        viewBox="0 0 28 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                          stroke="#FF2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.4482 7.10278V11.1028"
                          stroke="#FF2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.4482 15.1028H11.4582"
                          stroke="#FF2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {t("form_labels.invalid_field_error")}
                    </div>
                  )}
                </div>
                <div>
                  <label className="font-semibold text-white text-sm mt-1">
                    {t("form_labels.lastname")}
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder={t("form_labels.lastname")}
                    value={user.lastName}
                    onChange={handleChange}
                    className={`${
                      validate.lastName
                        ? "border-red-800 bg-red-550"
                        : "border-blue-800 bg-white"
                    } w-full h-14 placeholder-neutral-400 pl-5 border text-xs font-medium rounded-xl focus:outline-none`}
                  />

                  {validate.lastName && (
                    <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                      <svg
                        width="15"
                        height="12"
                        viewBox="0 0 28 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                          stroke="#FF2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.4482 7.10278V11.1028"
                          stroke="#FF2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.4482 15.1028H11.4582"
                          stroke="#FF2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {t("form_labels.invalid_field_error")}
                    </div>
                  )}
                </div>
              </div>
              <label className="font-semibold text-white text-sm mt-1">
                {t("form_labels.companyname")}
              </label>
              <input
                type="text"
                name="companyName"
                placeholder={t("form_labels.companyname")}
                value={user.companyName}
                onChange={handleChange}
                className={`${
                  validate.companyName
                    ? "border-red-800 bg-red-550"
                    : "border-blue-800 bg-white"
                } w-full h-14 placeholder-neutral-400 pl-5 border text-xs font-medium rounded-xl focus:outline-none`}
              />
              {validate.companyName && (
                <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 28 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4482 7.10278V11.1028"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4482 15.1028H11.4582"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t("form_labels.invalid_field_error")}
                </div>
              )}
              <label className="font-semibold text-white text-sm mt-1">
                {t("form_labels.country")}
              </label>
              <Select
                className={`${
                  validate.country
                    ? "border-red-800 bg-red-550"
                    : "border-blue-800 bg-white"
                } w-full h-14 placeholder-neutral-400 border text-xs font-medium rounded-xl focus:outline-none`}
                placeholder={t("form_labels.country")}
                onChange={handleDropdownChange("country")}
                options={Country}
              />
              {validate.country && (
                <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 28 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4482 7.10278V11.1028"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.4482 15.1028H11.4582"
                      stroke="#FF2828"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t("form_labels.invalid_field_error")}
                </div>
              )}
              <div className="flex flex-col justify-center items-center">
                <button
                  type="submit"
                  className="bg-gradient-to-r flex justify-center items-center gap-2 from-[rgb(4,9,51)] disabled:opacity-40 to-blue-800 mt-4 text-white rounded-lg w-full h-14 font-semibold text-xs text-align-center"
                  onClick={onSignUpClick}
                  disabled={loading}
                >
                  {loading && (
                    <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {t("buttons.submit")}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignUp;
