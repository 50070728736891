import { useCallback, useState, useRef, useEffect, useContext } from "react";
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  ControlButton,
  Controls,
  MarkerType,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";
import Markdown from "react-markdown";
import "reactflow/dist/style.css";
import ChildCard from "./ChildCard";
import { BellOutlined } from "@ant-design/icons";
import { BASE_URL, CleanKey, generateFormattedId } from "../../../constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import HostCard from "./HostCard";
import { useNavigate, useParams } from "react-router-dom";
import AppList from "../../Masters/Apps/AppList";
import { Spin } from "antd";
import CustomEdge from "./CustomConnector";
import Select from "react-select";
import { Switch, Modal, Input, TimePicker, Space } from "antd";
import { EdgeForm } from "./EdgeForms/EdgeForm";
import AppContext from "../../../AppContext";
import InactiveAlert from "./InactiveAlert";
import { useTranslation } from "react-i18next";
// import "./text-updater-node.css";
const rfStyle = {
  backgroundColor: "#B8CEFF",
};

const defaultNode = {
  id: "NEW-NODE",
  type: "middleware",
  position: { x: 500, y: 150 },
  data: {
    name: "",
    position: { x: 500, y: 150 },
    type: "middleware",
    nodeId: "NEW-NODE",
    editing: true,
  },
};

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = { middleware: HostCard, child: ChildCard };
const edgeTypes = { CustomEdge: CustomEdge };
function IntegrationForm() {
  const { t } = useTranslation();
  const activeStates = [
    { name: "Active", state: true },
    { name: "InActive", state: false },
  ];
  const defaultAlertConfig = {
    alertData: {
      interval: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
      emails: [],
    },
    active: false,
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const getId = () => Date.now().toString();
  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const connectingHandle = useRef(null);
  const [formPoup, setFormPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [alertConfig, setAlertConfig] = useState(defaultAlertConfig);
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedEdge, setSelectedEdge] = useState();
  const [markdown, setMarkdown] = useState("");
  const [active, setActive] = useState(true);
  const [documentationVisible, setDocumentationVisible] = useState(false);
  const { authCtx } = useContext(AppContext);
  const [company, setCompany] = useState({
    value: authCtx.profile?.companyId,
    label: authCtx.profile?.company,
    code: authCtx.profile?.companyCode,
  });
  console.log(authCtx.profile, "company");
  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Company`);
      const formattedCompanies = response.data.map((company) => ({
        value: company.id,
        label: company.name,
        code: company.code,
      }));
      setCompanies(formattedCompanies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  // const onConnectStart = useCallback((event, node) => {
  //   const { nodeId, handleId, handleType } = node;
  //   connectingNodeId.current = nodeId;
  //   connectingHandle.current = { id: handleId, type: handleType };
  // }, []);

  //   const isValidConnection = (connection) => connection.target === "B";
  const onNodeDelete = (nodeId) => {
    setNodes((pre) => pre.filter((x) => x.id !== nodeId));
  };

  const onNodeEdit = (nodeId) => {
    setNodes((pre) =>
      pre.map((x) =>
        x.id === nodeId
          ? { ...x, data: { ...x.data, editing: true } }
          : { ...x, data: { ...x.data, editing: false } }
      )
    );
    // setFormPopup(true);
  };
  const onNodeClose = (nodeId) => {
    setNodes((pre) =>
      pre.map((x) =>
        x.id === nodeId ? { ...x, data: { ...x.data, editing: false } } : x
      )
    );
    // setFormPopup(true);
  };

  const onFormSave = (node) => {
    setNodes((pre) =>
      pre.map((x) =>
        x.id === node.nodeId
          ? { ...x, data: { ...x.data, ...node, editing: false } }
          : x
      )
    );
  };

  // const onConnectEnd = useCallback(
  //   (event) => {
  //     if (!connectingNodeId.current || !connectingHandle.current) return;

  //     const targetIsPane = event.target.classList.contains("react-flow__pane");

  //     if (targetIsPane) {
  //       // we need to remove the wrapper bounds, in order to get the correct position
  //       const id = getId();
  //       const newNode = {
  //         id,
  //         position: screenToFlowPosition({
  //           x: event.clientX,
  //           y: event.clientY,
  //         }),
  //         data: {
  //           ...defaultNode.data,
  //           onDelete: onNodeDelete,
  //           onEdit: onNodeEdit,
  //           onSave: onFormSave,

  //           type:
  //             connectingHandle.current.type === "target"
  //               ? "source"
  //               : "destination",
  //           nodeId: id,
  //         },
  //         type: "child",
  //         origin: [0.5, 0.0],
  //       };

  //       setNodes((nds) => {
  //         const middlewareNode = nds.find((x) => x.data.type === "middleware");
  //         const concatData = nds.concat({
  //           ...newNode,
  //           data: { ...newNode.data, appList: middlewareNode.data.appList },
  //         });
  //         const finalData = concatData.map((x) =>
  //           x.data.type === "middleware"
  //             ? {
  //                 ...x,
  //                 data: {
  //                   ...x.data,
  //                   nodeCounts:
  //                     concatData.filter((y) => y.data.type === "destination")
  //                       .length + 1,
  //                 },
  //               }
  //             : x
  //         );
  //         return finalData;
  //       });
  //       setEdges((eds) =>
  //         eds.concat({
  //           id,
  //           [connectingHandle.current.type === "source"
  //             ? "sourceHandle"
  //             : "targetHandle"]: connectingHandle.current.id,
  //           source:
  //             connectingHandle.current.type === "source"
  //               ? connectingNodeId.current
  //               : id,
  //           target:
  //             connectingHandle.current.type === "source"
  //               ? id
  //               : connectingNodeId.current,
  //           markerEnd: {
  //             type: MarkerType.ArrowClosed,
  //           },
  //           animated: true,
  //           type:
  //             connectingHandle.current.type === "source" ? "CustomEdge" : "",
  //         })
  //       );
  //     }
  //   },
  //   [screenToFlowPosition]
  // );

  const getDocumentation = async (integrationId) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/IntegrationItems/documentation`
      );
      let documentation = res.data;
      documentation = documentation.replace(
        /YOUR_INTEGRATION_ID/g,
        integrationId.toLowerCase()
      );
      setMarkdown(documentation);
    } catch (error) {
      console.error("Error fetching documentation:", error);
    }
  };

  const loadLookupData = async (companyId) => {
    const res = await axios.get(
      `${BASE_URL}/api/App?companyId=${companyId ? companyId : ""}`
    );
    if ((res.status = 200)) {
      return res.data.filter((i) => i.active);
    }
  };

  const getPosition = (nodelength, lastNode, middlewareNode) => {
    const isEven = nodelength % 2 === 0;
    if (nodelength === 0) {
      return {
        x: middlewareNode.position.x + 600,
        y: middlewareNode.position.y + 43,
      };
    } else if (nodelength === 1) {
      return {
        x: lastNode.position.x,
        y: lastNode.position.y - 150,
      };
    } else {
      const x = lastNode.position.x;
      const y = isEven ? lastNode.position.y + 150 : lastNode.position.y - 150;
      return {
        x,
        y,
      };
    }
  };

  const onNodeAdd = (target, middlewareNode) => {
    const id = target === "left" ? "source" : getId();
    const newNode = {
      id,
      position: {
        x: middlewareNode.position.x - 300,
        y: middlewareNode.position.y + 43,
      },
      data: {
        ...defaultNode.data,
        onDelete: onNodeDelete,
        onEdit: onNodeEdit,
        onClose: onNodeClose,
        onSave: onFormSave,
        type: target === "left" ? "source" : "destination",
        nodeId: id,
      },
      type: "child",
      origin: [0.5, 0.0],
    };
    if (target === "left") {
      setNodes((nds) => {
        // const middlewareNode = nds.find((x) => x.data.type === "middleware");
        const sourceNodes = nds.filter((y) => y.data.type === "source");
        if (sourceNodes.length === 0) {
          const concatData = nds.concat({
            ...newNode,
            data: { ...newNode.data, appList: middlewareNode.appList },
          });
          setEdges((eds) =>
            eds.concat({
              id,
              target: defaultNode.id,
              source: id,
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
              animated: true,
              type: "CustomEdge",
            })
          );
          return concatData;
        } else return nds;
      });
    }
    if (target === "right") {
      setNodes((nds) => {
        const destNodes = nds.filter((y) => y.data.type === "destination");
        const concatData = nds.concat({
          ...newNode,
          position: getPosition(
            destNodes.length,
            destNodes.length > 1
              ? destNodes[destNodes.length - 2]
              : destNodes[0],
            middlewareNode
          ),
          data: { ...newNode.data, appList: middlewareNode.appList },
        });
        return concatData;
      });
      setEdges((eds) =>
        eds.concat({
          id,
          target: id,
          source: middlewareNode.nodeId,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          animated: true,
          type: "CustomEdge",
        })
      );
    }
  };

  const loadData = async (appList, companies) => {
    const res = await axios.get(`${BASE_URL}/api/IntegrationItems/${id}`);
    if ((res.status = 200)) {
      let data = res.data;
      console.log(data.readableId.toLowerCase(), "data");
      getDocumentation(data.readableId);
      if (data.alertConfig) setAlertConfig(data.alertConfig);
      setActive(data.status);
      setCompany({
        value: data.companyId,
        label: data.company,
        code: data.companyCode,
      });
      const destinations = data.destinations.map((x, i) => ({
        id: "destination" + (i + 1),
        type: "child",
        position: x.position,
        data: {
          nodeId: "destination" + (i + 1),
          type: "destination",
          onDelete: onNodeDelete,
          onEdit: onNodeEdit,
          onClose: onNodeClose,
          onSave: onFormSave,
          connectivity: x.connectivity,
          appList,
          app: {
            id: x.id,
            appId: x.systemId,
            name: x.name,
            iconUrl: x.iconUrl,
            description: x.description,
          },
        },
      }));
      const middlewareNode = {
        id: generateFormattedId(data.id, data.companyCode),
        type: "middleware",
        position: data.position,
        createdDate: data.createdDate,
        data: {
          nodeId: generateFormattedId(data.id, data.companyCode),
          nodeCounts: destinations.length + 1,
          name: data.name,
          appList,
          position: data.position,
          companyId: data.companyId,
          company: data.company,
          companyCode: data.companyCode,
          description: data.description,
          type: "middleware",
          companies: companies,
          onDelete: onNodeDelete,
          onEdit: onNodeEdit,
          onClose: onNodeClose,
          onSave: onFormSave,
          onNodeAdd: onNodeAdd,
        },
      };
      const sourceNode = {
        id: "source",
        type: "child",
        position: data.source.position,
        data: {
          nodeId: "source",
          type: "source",
          connectivity: data.source.connectivity,
          appList,
          onDelete: onNodeDelete,
          onEdit: onNodeEdit,
          onClose: onNodeClose,
          onSave: onFormSave,
          app: {
            id: data.source.id,
            appId: data.source.systemId,
            name: data.source.name,
            iconUrl: data.source.iconUrl,
            description: data.source.description,
          },
        },
      };
      setNodes([middlewareNode, sourceNode, ...destinations]);
      const sourceEdge = {
        id: middlewareNode.id,
        // targetHandle: middlewareNode.id + "target",
        source: sourceNode.id,
        target: middlewareNode.id,
        animated: true,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: "black",
        },
        style: { stroke: "black" },
        type: "CustomEdge",
      };
      const destEdges = destinations.map((x, i) => ({
        id: x.id,
        source: middlewareNode.id,
        animated: true,
        type: "CustomEdge",
        target: x.id,
      }));
      setEdges([sourceEdge, ...destEdges]);
    }
  };

  const hasDuplicates = (array, property) => {
    const values = [];
    for (const item of array) {
      const value = item[property];
      if (value !== null) {
        if (values.includes(value)) {
          return true; // Duplicate found
        }
        values.push(value);
      }
    }
    return false; // No duplicates
  };
  const onSave = async () => {
    setBtnLoading(true);
    const hostCard = nodes.find((x) => x.data.type === "middleware");
    const sourceData = nodes.find((x) => x.data.type === "source");
    const destData = nodes.filter((x) => x.data.type === "destination");
    const finalData = {
      id: "new",
      readableId: hostCard.id,
      name: hostCard.data.name,
      description: hostCard.data.description,
      status: active,
      createdUserId: authCtx.profile.id,
      company: company.label,
      companyId: company.value,
      companyCode: company.code,
      createdDate: hostCard.createdDate,
      position: hostCard.position,
      alertConfig: alertConfig,
      source: {
        id: sourceData.data.app.id,
        systemId: sourceData.data.app.appId,
        name: sourceData.data.app.name,
        description: sourceData.data.app.description,
        position: sourceData.position,
        connectivity: sourceData.data.connectivity,
        iconUrl: sourceData.data.app.iconUrl,
      },
      destinations: destData.map((x) => ({
        id: x.data.app.id,
        systemId: x.data.app.appId,
        name: x.data.app.name,
        description: x.data.app.description,
        position: x.position,
        connectivity: x.data.connectivity,
        iconUrl: x.data.app.iconUrl,
      })),
    };
    if (
      hasDuplicates([finalData.source, ...finalData.destinations], "systemId")
    ) {
      toast.error("Duplicate systemId found in source or destinations.");
      setBtnLoading(false);
      return;
    }
    if (id === "new")
      try {
        let res = await axios.post(
          `${BASE_URL}/api/IntegrationItems`,
          finalData
        );
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          toast.success("Node created successfully!");
          navigate(`/integration/${res.data.id}`);
        }
      } catch (err) {
        toast.error("An error occured while creating catalog!");
      }
    else {
      finalData.id = id;
      try {
        let res = await axios.put(
          `${BASE_URL}/api/IntegrationItems/${id}`,
          finalData
        );
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          toast.success("Integration updated successfully!");
          navigate(`/integration/${id}`);
        }
      } catch (err) {
        toast.error("An error occured while updating catalog!");
      }
    }
    setBtnLoading(false);
  };

  const validateForm = () => {
    const sourceNode = nodes.filter((x) => x.data.type === "source");
    const destNodes = nodes.filter((x) => x.data.type === "destination");
    return (
      nodes.some((x) => x.type !== "middleware" && !x.data.app) ||
      sourceNode.length === 0 ||
      destNodes.length === 0
    );
  };

  useEffect(() => {
    // if (apps.length > 0) {
    const loadComponent = async () => {
      setLoading(true);

      const appList = await loadLookupData(authCtx.profile.companyId);
      const companies = await fetchCompanies();
      if (id !== "new") loadData(appList, companies);
      else
        setNodes([
          {
            ...defaultNode,
            data: {
              ...defaultNode.data,
              appList,
              onDelete: onNodeDelete,
              onEdit: onNodeEdit,
              onClose: onNodeClose,
              onSave: onFormSave,
              onNodeAdd: onNodeAdd,
              companyId: authCtx.profile?.companyId,
              company: authCtx.profile?.company,
              companyCode: authCtx.profile?.companyCode,
              companies: companies,
            },
          },
        ]);
      setLoading(false);
    };
    loadComponent();
    // }
  }, [id]);
  const handleEmailChange = (emails) => {
    setAlertConfig({
      ...alertConfig,
      alertData: { ...alertConfig.alertData, emails },
    });
  };
  // const setPopup = () => {
  //   console.log("");
  // };

  const getMiddleWareNode = () => {
    const res = nodes.find((x) => x.data.type === "middleware");
    return res;
  };
  const handleInactiveAlertClick = () => {
    setAlertModalVisible(true);
  };

  const handleAlertSave = () => {
    // Check if interval is not set
    if (
      alertConfig.alertData &&
      !alertConfig.alertData.interval.days &&
      !alertConfig.alertData.interval.hours &&
      !alertConfig.alertData.interval.minutes
    ) {
      toast.error("Please set the alert interval.");
      return;
    }

    // Check if emails are not set
    if (alertConfig.alertData && alertConfig.alertData.emails.length === 0) {
      toast.error("Please enter at least one email address for the alert.");
      return;
    }
    // Proceed with saving if interval and emails are set
    setAlertModalVisible(false);
  };

  const handleAlertCancel = () => {
    // Handle cancel logic here
    setAlertModalVisible(false);
  };
  const handleDocumentationCancel = () => {
    // Handle cancel logic here
    setDocumentationVisible(false);
  };

  const handleDocumentationClick = async () => {
    await getDocumentation();
    // Handle cancel logic here
    setDocumentationVisible(true);
  };
  return (
    <div className="flex h-full w-full">
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        ref={reactFlowWrapper}
      >
        <ReactFlow
          zoomOnDoubleClick={false}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onEdgeDoubleClick={(e, edge) => {
            if (nodes.some((x) => x.type !== "middleware" && x.data.app)) {
              setSelectedEdge({ ...edge });
              setFormPopup(true);
            }
          }}
          // onConnectStart={onConnectStart}
          // onConnectEnd={onConnectEnd}
          nodesConnectable={false}
          fitView
          fitViewOptions={{ duration: 400, padding: id === "new" ? 2.5 : 0.4 }}
          autoPanOnConnect
        >
          <div className="absolute gap-2 items-center flex left-0 text-lg font-medium px-4 py-3">
            <div className="w-fit text-nowrap">
              {getMiddleWareNode()?.data?.nodeId}
            </div>
            {authCtx.profile.role === 100 && (
              <Select
                className="w-52 text-sm z-50"
                value={company} // Assuming this is the selected option
                placeholder="Select Company"
                onChange={(selectedOption) => {
                  setCompany(selectedOption); // Set selected option directly
                }}
                options={companies}
              />
            )}
          </div>

          <Spin
            spinning={loading}
            size="large"
            tip="Loading..."
            className="z-50 absolute top-1/2 left-1/2 text-blue-500 -translate-x-1/2 -translate-y-1/2"
          />

          <div className="transition-all ease-in-out duration-200 absolute right-0 m-4 z-50 gap-3 w-fit flex">
            <button
              onClick={() => handleDocumentationClick()}
              className={` text-sm flex gap-2 items-center justify-center font-medium px-2 py-2 rounded disabled:opacity-50 text-white bg-gray-700`}
            >
              Help ?
            </button>
            <div
              className={`inline-flex rounded group md:w-44 w-36 border ${
                active ? "border-green-600" : "border-red-600"
              }`}
            >
              {activeStates.map((x, index) => (
                <button
                  type="button"
                  className={`md:text-xs text-xxs transition-all ease-in-out duration-200 font-medium text-center w-1/2 py-2 px-1 ${
                    x.name === "Active"
                      ? "rounded-tl rounded-bl"
                      : "rounded-tr rounded-br"
                  } ${
                    active === x.state
                      ? ` ${
                          x.name === "Active" ? "bg-green-600" : "bg-red-600"
                        } text-white`
                      : "text-black bg-white"
                  }`}
                  key={index}
                  onClick={() => setActive(x.state)}
                >
                  {t(`form_labels.${CleanKey(x.name)}`)}
                </button>
              ))}
            </div>

            <button
              onClick={() => handleInactiveAlertClick()}
              className={` text-sm flex gap-2 items-center justify-center font-medium px-2 py-2 rounded disabled:opacity-50 ${
                alertConfig.active
                  ? "bg-green-600 text-green-100"
                  : "text-white bg-red-600"
              }`}
              disabled={btnLoading}
            >
              {t(`buttons.inactive_alert`)} <BellOutlined />
            </button>
            <button
              onClick={() => onSave()}
              className="bg-green-200 text-sm flex gap-2 items-center justify-center font-medium text-green-600 w-20 py-2 rounded disabled:opacity-50"
              disabled={btnLoading || validateForm()}
            >
              <Spin size="small" spinning={btnLoading} />
              {t(`buttons.save`)}
            </button>
            <button
              onClick={() => navigate("/integrationList")}
              className="bg-red-200 text-sm font-medium text-red-600 px-5 py-2 rounded"
            >
              {t(`buttons.cancel`)}
            </button>
          </div>
          <Background />
        </ReactFlow>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </div>
      {formPoup && (
        <EdgeForm
          setPopup={setFormPopup}
          setNodes={setNodes}
          selectedEdge={selectedEdge}
          setSelectedEdge={setSelectedEdge}
          nodes={nodes}
        />
      )}
      <Modal
        title="Interval Alert Configuration"
        visible={alertModalVisible}
        onCancel={handleAlertCancel}
        footer={[
          <button
            key="cancel"
            onClick={handleAlertCancel}
            className="bg-red-200 text-sm mb-4 font-medium text-red-600 px-5 py-2 rounded"
          >
            {t(`buttons.cancel`)}
          </button>,
          <button
            className="bg-green-200 ml-2 text-sm font-medium text-green-600 px-5 py-2 rounded"
            key="save"
            onClick={handleAlertSave}
          >
            {t(`buttons.save`)}
          </button>,
        ]}
      >
        <InactiveAlert
          handleEmailChange={handleEmailChange}
          setAlertConfig={setAlertConfig}
          alertConfig={alertConfig}
        />
      </Modal>
      <Modal
        title="Documentation"
        width={"60vw"}
        visible={documentationVisible}
        onCancel={handleDocumentationCancel}
        footer={[]}
      >
        <div className="h-[70vh] overflow-y-auto markdown-body">
          <Markdown>{markdown}</Markdown>
        </div>
      </Modal>
    </div>
  );
}

export default () => (
  <ReactFlowProvider>
    <IntegrationForm />
  </ReactFlowProvider>
);
