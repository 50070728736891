import { Input, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppIconPath, BASE_URL, Country } from "../../../constants";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
const CompanyList = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: "",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("form_labels.name"),
      dataIndex: "name",
      render: (name, record) => (
        <div className="flex items-center">
          {record.iconUrl && (
            <img
              src={AppIconPath + record.iconUrl}
              alt="Logo"
              className="w-6 h-6 mr-2"
            />
          )}
          {name}
        </div>
      ),
    },
    {
      title: t("form_labels.legal_name"),
      dataIndex: "legalName",
    },
    {
      title: t("form_labels.country"),
      dataIndex: "country",
      render: (country) => Country.find((x) => x.value === country)?.label,
    },
    {
      title: t("form_labels.status"),
      dataIndex: "active",
      render: (active) => (
        <span style={{ color: active ? "green" : "red" }}>
          {active ? t("form_labels.active") : t("form_labels.inactive")}
        </span>
      ),
    },
    {
      title: t("form_labels.action"),
      dataIndex: "id",
      render: (id) => (
        <button
          className="text-blue-500"
          onClick={() => navigate(`/companyForm/${id}`)}
        >
          {t("buttons.edit")}
        </button>
      ),
    },
  ];
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const loadData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/Company`);
      if (res.status === 200) {
        setDataList(res.data);
        setFilteredData(res.data);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSearch = (value) => {
    const filtered = dataList.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.legalName.toLowerCase().includes(value.toLowerCase()) ||
        Country.find((x) => x.value === item.country)
          ?.label.toLowerCase()
          .includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-6">
      <div className="md:flex justify-between sm:items-center">
        <div className="md:flex w-full gap-4">
          <h1 className="md:text-xl  w-fit font-semibold leading-6 text-gray-900">
            {t("pages.companies")}
          </h1>
          <Input.Search
            className="w-2/5"
            placeholder={t("pages.companies_search_label")}
            onSearch={handleSearch}
          />
        </div>

        <div className="mt-4 flex gap-2 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() => navigate("/companyForm/new")}
          >
            <PlusOutlined /> {t("form_labels.company")}
          </button>
        </div>
      </div>
      <div className="flex lg:my-3">
        <Table
          className="border w-full"
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
        />
      </div>
    </div>
  );
};

export default CompanyList;
