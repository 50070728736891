import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Select from "react-select";
import { Tree } from "antd";
import { Switch } from "antd";
import { BASE_URL, Roles } from "../../../constants";
import { TeamOutlined } from "@ant-design/icons";
import AppContext from "../../../AppContext";
import { useTranslation } from "react-i18next";

const UserForm = () => {
  const { t } = useTranslation();
  const defaultEntity = {
    name: "",
    password: "",
    emailId: "",
    role: "",
    active: true,
    companyId: null,
    singleSignOn: false,
  };

  const navigate = useNavigate();
  let { id } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(defaultEntity);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companies, setCompanies] = useState([]);
  const { authCtx } = React.useContext(AppContext);

  // Function to fetch all company data
  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Company`);
      const formattedCompanies = response.data.map((company) => ({
        value: company.id,
        label: company.name,
        code: company.code,
      }));
      setCompanies(formattedCompanies);
      if (id === "new") {
        const selectedOption = formattedCompanies.find(
          (x) => x.value === authCtx.profile.companyId
        );
        setUser((prevState) => {
          return {
            ...prevState,
            companyId: selectedOption.value,
            company: selectedOption.label,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const onValueChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
      setUser({ ...user, [name]: value });
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const handleDropdownChange = (fieldName) => (selectedOption) => {
    if (selectedOption !== undefined) {
      setUser((prevState) => {
        if (fieldName === "company") {
          // Assuming selectedOption is an object containing companyId and companyName
          return {
            ...prevState,
            companyId: selectedOption.value,
            company: selectedOption.label,
            companyCode: selectedOption.code,
          };
        } else {
          return {
            ...prevState,
            [fieldName]: selectedOption.value,
          };
        }
      });
    }
  };

  const onCancelClick = () => {
    navigate("/userList");
  };

  const fetchData = async () => {
    let res = await axios.get(`${BASE_URL}/api/User/GetById?userId=${id}`);
    setUser(res.data);
  };

  useEffect(() => {
    fetchCompanies();
    if (id !== "new") {
      fetchData();
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    setIsSubmitted(true);

    if (form.checkValidity()) {
      if (password !== confirmPassword) {
        toast.error("Passwords do not match!");
        return;
      }

      try {
        setLoading(true);
        if (id === "new") {
          // Call the create API
          let res = await axios.post(`${BASE_URL}/api/User`, user);
          setLoading(false);
          toast.success("User Master created successfully!");
          navigate(`/userForm/${res.data}`);
        } else {
          // Call the update API
          let res = await axios.put(`${BASE_URL}/api/User`, user);
          setLoading(false);
          toast.success("User Master updated successfully!");
          navigate(`/userForm/${id}`);
        }
      } catch (err) {
        setLoading(false);
        if (id === "new") {
          toast.error("An error occurred while creating User Master!");
        } else {
          toast.error("An error occurred while updating User Master!");
        }
      }
    }
  };

  //   useEffect(() => {
  //     // Extract moduleIds from the user object and set as selectedModules
  //     if (user.modules && user.modules.length > 0) {
  //       const selectedModuleIds = user.modules.map((module) =>
  //         module.moduleId.toString()
  //       );
  //       setSelectedModules(selectedModuleIds);
  //     }
  //   }, [user.modules]);
  const handleSwitchChange = (fieldName) => (checked) => {
    setUser((prevState) => ({
      ...prevState,
      [fieldName]: checked,
    }));
  };
  return (
    <div className="px-7 py-5 bg-white w-full h-screen">
      <h2
        onClick={onCancelClick}
        className="text-ActiveUsers cursor-pointer text-md mb-2 text-blue-600 font-semibold"
      >
        {t("pages.users")}{" "}
        <span className="text-black">
          / {id === "new" ? t("buttons.new") : t("buttons.update")} User
        </span>
      </h2>
      <form noValidate onSubmit={onSubmit} className="">
        <div className="lg:w-3/5">
          <div className="grid grid-cols-2  items-center gap-4 mt-2">
            <div className=" ">
              <p className="text-sm font-medium">{t("form_labels.username")}</p>
              <input
                type="text"
                name="userName"
                value={user?.userName}
                onChange={onValueChange}
                placeholder="Username"
                className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
              />
              {isSubmitted && !user?.userName && (
                <p className="text-red-700 mt-1 text-xs font-semibold">
                  This field is required
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-medium">{t("form_labels.role")}</p>
              <div className="mt-2 h-8">
                <Select
                  className="w-full"
                  options={Roles}
                  value={
                    Roles && Roles.find((option) => option.value === user?.role)
                  }
                  onChange={handleDropdownChange("role")}
                />
                {isSubmitted && !user?.role && (
                  <p className="text-red-700 mt-1 text-xs font-semibold">
                    This field is required
                  </p>
                )}
              </div>
            </div>
            <div className="">
              <p className="text-sm font-medium">
                {t("form_labels.full_name")}
              </p>
              <input
                type="text"
                name="name"
                value={user?.name}
                onChange={onValueChange}
                placeholder="First Name"
                className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
              />
              {isSubmitted && !user?.name && (
                <p className="text-red-700 mt-1 text-xs font-semibold">
                  This field is required
                </p>
              )}
            </div>

            <div className="space-y-3">
              <div>
                <p className="text-sm font-medium">{t("form_labels.email")}</p>
                <input
                  type="text"
                  name="emailId"
                  value={user.emailId}
                  required
                  onChange={onValueChange}
                  placeholder="sample@mail.com"
                  className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
                />
                {isSubmitted && !user?.emailId && (
                  <p className="text-red-700 mt-1 text-xs font-semibold">
                    This field is required
                  </p>
                )}
              </div>
            </div>
            {user.role !== 100 && authCtx.profile.role === 100 && (
              <div className="-mt-2">
                <p className="text-sm font-medium">
                  {t("form_labels.company")}
                </p>
                <div className="mt-2 h-8">
                  <Select
                    className="w-full h-7"
                    options={companies}
                    required
                    value={
                      companies &&
                      companies.find(
                        (option) => option.value === user?.companyId
                      )
                    }
                    onChange={handleDropdownChange("company")}
                  />
                  {isSubmitted && !user?.companyId && (
                    <p className="text-red-700 mt-1 text-xs font-semibold">
                      This field is required
                    </p>
                  )}
                </div>
              </div>
            )}
            {id === "new" && (
              <>
                <div>
                  <p className="text-sm font-medium">
                    {t("form_labels.password")}
                  </p>
                  <input
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={onValueChange}
                    placeholder={t("form_labels.password")}
                    className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
                  />
                  {isSubmitted && !user?.password && (
                    <p className="text-red-700 mt-1 text-xs font-semibold">
                      This field is required
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm font-medium">
                    {t("form_labels.confirm_password")}
                  </p>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={user.confirmPassword}
                    onChange={onValueChange}
                    placeholder={t("form_labels.confirm_password")}
                    className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
                  />
                  {isSubmitted && !confirmPassword && (
                    <p className="text-red-700 mt-1 text-xs font-semibold">
                      This field is required
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="justify-between items-end flex mt-6">
            <div className="flex gap-2 justify-between w-1/2">
              <div className="flex items-end h-full gap-2">
                <p className="text-sm font-medium">{t("form_labels.sso")} :</p>
                <Switch
                  className="bg-neutral-900"
                  checked={user.singleSignOn}
                  onChange={handleSwitchChange("singleSignOn")}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                />
              </div>
              <div className="flex items-end h-full gap-2">
                <p className="text-sm font-medium">
                  {t("form_labels.status")} :
                </p>
                <Switch
                  checkedChildren={t("form_labels.active")}
                  unCheckedChildren={t("form_labels.inactive")}
                  className="bg-neutral-900"
                  // className={user.active ? "active-switch" : "inactive-switch"}
                  checked={user.active}
                  onChange={handleSwitchChange("active")}
                />
              </div>
            </div>
            <div className="flex gap-2">
              <button
                type="button"
                className="block rounded-md bg-white px-3 py-2 text-center outline-blue-500 outline-[1px] outline text-sm font-semibold text-blue-600 hover:text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={onCancelClick}
              >
                {t("buttons.cancel")}
              </button>
              <button
                type="submit"
                className="block rounded-md disabled:opacity-40 bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                disabled={loading}
                // onClick={onSubmit}
              >
                {id === "new" ? t("form_labels.create") : t("buttons.update")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
