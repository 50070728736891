import React from "react";
// import { AlphaVersion } from "./constant";

const AppLogo = () => {
  return (
    <>
      <img src="logo512.png" alt="LOGO" className="h-9" />
      <label
        className="font-extrabold text-lg text-white leading-none text-start"
        style={{ fontFamily: "'Chakra Petch', sans-serif" }}
      >
        HERMES Integration Platform
      </label>

      {/* <div className="flex text-start items-center h-9 text-white text-xxs font-normal border-l border-white pl-3">
        <img src="logo_desc.svg" alt="LOGO" className="" />
      </div> */}
    </>
  );
};

export default AppLogo;
