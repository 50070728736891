import { Button } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";

const DynOutputHandle = (props) => {
  const { idx, position, type, index, totalHeight, outputcount } = props;
  const topPosition = (index + 1) * (totalHeight / (outputcount + 1));
  return (
    <Handle
      type={type}
      id={idx + type}
      key={index}
      position={position}
      style={{ top: topPosition }}
    />
  );
};

const handleStyle = { left: 10 };

function HostCard({ data, isConnectable }) {
  const { t } = useTranslation();
  const [outputcount, setOutputCount] = useState(data.nodeCounts);
  const containerRef = useRef();
  const updateNodeInternals = useUpdateNodeInternals();
  const [nodeData, setNodeData] = useState(data);

  const rightHandles = useMemo(() => {
    return Array(outputcount)
      .fill(null)
      .map((_, i) => (
        <DynOutputHandle
          key={i}
          idx={i + "-right-" + data.nodeId}
          position={Position.Right}
          type={"source"}
          index={i}
          outputcount={outputcount}
          totalHeight={208}
        />
      ));
  }, [outputcount, data.nodeId]);

  useEffect(() => {
    updateNodeInternals(data.nodeId);
    setOutputCount(data.nodeCounts);
  }, [data.nodeCounts]);

  const GetIcon = ({ source }) => {
    return <img src={source} className="h-6" />;
  };
  return (
    <div
      ref={containerRef}
      className="w-96 flex items-center justify-between h-52 bg-cyan-100 border border-cyan-400 border-x-8 rounded-lg shadow"
    >
      <Handle
        type={"target"}
        id={data.nodeId + "target"}
        position={Position.Left}
        draggable={false}
      />
      <Button
        icon={<GetIcon source={"add-icon.svg"} />}
        className="border-none shadow-none z-50 w-fit ml-1"
        style={{ width: "fit-content" }}
        onClick={() => data.onNodeAdd("left", data)}
      ></Button>
      <div className="flex px-6 w-full h-full flex-col justify-center">
        <div className="flex justify-between">
          <label htmlFor="text" className="text-lg font-medium">
            {t(`buttons.integration`)} Middleware
          </label>
          {data.editing ? (
            <button
              className="border-green-600 transition-all ease-in-out duration-200 border px-2 py-0 rounded"
              onClick={() => data.onSave(nodeData)}
            >
              <img src="save-icon.svg" className="h-3 w-3" />
            </button>
          ) : (
            <button
              className="transition-all ease-in-out duration-200"
              onClick={() => data.onEdit(data.nodeId)}
            >
              <img src="Edit-icon.svg" className="h-4 w-4" />
            </button>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4 mt-5 ml-2">
          <div className="font-medium flex flex-col justify-between text-sm">
            <label className="text-xs text-slate-400">ID</label>
            <label>{data.nodeId}</label>
          </div>
          <div className="font-medium flex flex-col justify-between text-sm">
            <label className="text-xs text-slate-400">
              {t(`form_labels.short_name`)}
            </label>
            {!data.editing ? (
              <label className="transition-all ease-in-out duration-200">
                {data.name}
              </label>
            ) : (
              <input
                type="text"
                value={nodeData.name}
                onChange={(e) =>
                  setNodeData((pre) => ({ ...pre, name: e.target.value }))
                }
                className="focus:outline-none transition-all ease-in-out duration-200 py-1 h-6 mt-1 text-xs border border-slate-300 px-1 rounded"
              />
            )}
          </div>
          <div className="font-medium col-span-2 flex flex-col justify-between text-sm">
            <label htmlFor="text" className="text-xs text-slate-400">
              {t(`form_labels.description`)}
            </label>
            {!data.editing ? (
              <label className="transition-all ease-in-out duration-200">
                {data.description}
              </label>
            ) : (
              <textarea
                value={nodeData?.description}
                onChange={(e) =>
                  setNodeData((pre) => ({
                    ...pre,
                    description: e.target.value,
                  }))
                }
                className="focus:outline-none transition-all ease-in-out duration-200 py-1 mt-1 text-xs border border-slate-300 px-1 rounded"
              />
            )}
          </div>
        </div>
      </div>
      <Button
        icon={<GetIcon source={"add-icon.svg"} />}
        className="border-none shadow-none z-50 w-fit mr-2"
        style={{ width: "fit-content" }}
        onClick={() => data.onNodeAdd("right", data)}
      ></Button>
      <Handle
        type={"source"}
        id={data.nodeId + "source"}
        position={Position.Right}
        draggable={false}
      />
      {/* {rightHandles} */}
    </div>
  );
}

export default HostCard;
