import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="mt-2">
      <div className="dropdown inline-block relative">
        <button className=" font-semibold rounded inline-flex items-center">
          <img src="globe.png" className="w-6 h-6" alt="Language" />
        </button>
        <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
          <li className="">
            <button
              className="w-32 border-[1px] border-b-0 rounded-t bg-white hover:text-white hover:bg-blue-950 py-2 px-4 block whitespace-no-wrap"
              onClick={() => changeLanguage("en")}
            >
              English
            </button>
          </li>
          <li className="">
            <button
              className="w-32 border-[1px] border-b-0  bg-white hover:text-white hover:bg-blue-950 py-2 px-4 block whitespace-no-wrap"
              onClick={() => changeLanguage("es")}
            >
              Spanish
            </button>
          </li>
          <li className="">
            <button
              className="w-32 border-[1px] rounded-b bg-white hover:text-white hover:bg-blue-950 py-2 px-4 block whitespace-no-wrap"
              onClick={() => changeLanguage("it")}
            >
              Italian
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LanguageSwitcher;
