import { Checkbox, Popover, Select, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const symbolData = [
  { name: "integer", symbol: "diamond-icon.svg", label: "Int" },
  { name: "string", symbol: "diamond-icon.svg", label: "Str" },
  { name: "object", symbol: "object-icon.svg" },
  { name: "array", symbol: "array-icon.svg" },
  { name: "boolean", symbol: "diamond-icon.svg", label: "Bool" },
];

const transformationTypeData = [
  { name: "copy", symbol: "copy-icon.svg", hoverText: "Copied from source" },
  {
    name: "constant",
    symbol: "docAdd-icon.svg",
    hoverText: "Added from Parent",
  },
];

const typeValues = [
  { value: "string", label: "String" },
  { value: "integer", label: "Integer" },
  { value: "object", label: "Object" },
  { value: "array", label: "Array" },
  { value: "boolean", label: "Boolean" },
];

const PopoverContent = ({ data, onSave, onClose }) => {
  const { t } = useTranslation();
  const [nodeData, setNodeData] = useState({
    ...data,
    isNull: data.isNull || false,
    defaultValue: data.defaultValue || "",
  });
  const transormSymbol = transformationTypeData.find(
    (x) => x.name === data.transformationType
  );

  const onHandleChange = (e) => {
    setNodeData((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const onSelectChange = (selectedValue) => {
    setNodeData((pre) => ({
      ...pre,
      type: selectedValue,
      value:
        selectedValue === "string"
          ? ""
          : selectedValue === "integer"
          ? 0
          : selectedValue === "boolean"
          ? false
          : null,
    }));
  };

  const onCheckBoxChange = (e) => {
    setNodeData((pre) => ({ ...pre, isNull: e.target.checked }));
  };

  return (
    <div className="inline-block px-3 pb-1 align-bottom bg-white rounded-lg text-left overflow-hidden">
      <div className="flex flex-col gap-1 text-xs font-medium">
        <div className="flex self-end pb-3 items-center gap-1">
          <label className="">{t("form_labels.transformation_type")}</label>:
          <Tooltip title={transormSymbol?.hoverText} trigger="hover">
            <img src={transormSymbol?.symbol} className="h-4" />
          </Tooltip>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="flex gap-1">
            <label className="w-20">{t("form_labels.name")}</label>:
            <input
              className="w-24 h-6 pl-2 focus:outline-none border rounded"
              name="name"
              value={nodeData?.name || ""}
              onChange={onHandleChange}
            />
          </div>
          <div className="flex gap-1 font-medium">
            <label className="w-20">{t("form_labels.type")}</label>:
            <div className="font-medium">
              <Select
                size="small"
                className="w-24 font-medium text-xs"
                onChange={onSelectChange}
                options={typeValues}
                value={nodeData.type}
              />
            </div>
          </div>
          {nodeData.type !== "object" && nodeData.type !== "array" ? (
            <div className="flex gap-1">
              <label className="w-20">{t("form_labels.default_value")}</label>:
              {nodeData.type === "string" ? (
                <input
                  className="w-24 h-6 pl-2 border focus:outline-none rounded"
                  value={nodeData?.value || ""}
                  name="value"
                  onChange={onHandleChange}
                />
              ) : nodeData.type === "integer" ? (
                <input
                  type="number"
                  className="w-24 h-6 pl-2 border focus:outline-none rounded"
                  value={nodeData.value}
                  name="value"
                  onChange={onHandleChange}
                />
              ) : nodeData.type === "boolean" ? (
                <Checkbox
                  onChange={onCheckBoxChange}
                  name="value"
                  checked={nodeData.value}
                  value={nodeData.value}
                />
              ) : null}
            </div>
          ) : null}
          <div className="flex gap-1">
            <label className="w-20">Is Null</label>:
            <Checkbox
              onChange={onCheckBoxChange}
              checked={nodeData.isNull}
              value={nodeData.isNull}
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-50 flex gap-2 justify-end mt-3 text-xs">
        <button
          type="button"
          onClick={() => onClose()}
          className="w-fit inline-flex justify-center rounded-md border border-transparent px-2 py-1 bg-indigo-500 font-medium text-white"
        >
          {t("buttons.close")}
        </button>
        <button
          type="button"
          onClick={() => onSave(nodeData)}
          className="w-fit inline-flex justify-center rounded-md border border-transparent px-2 py-1 bg-green-500 font-medium text-white"
        >
          {t("buttons.save")}
        </button>
      </div>
    </div>
  );
};
const NodeTemplate = ({
  data,
  templateType,
  getNodeById,
  jsonMapper,
  treeRef,
  setJsonMapper,
  setKey,
}) => {
  const [openModel, setOpenModel] = useState(false);
  const [editingNode, setEditingNode] = useState();
  const dataSymbol = symbolData.find((x) => x.name === data.type);
  const parentSymbol = symbolData.find((x) => x.name === data.parentType);
  const transormSymbol = transformationTypeData.find(
    (x) => x.name === data.transformationType
  );
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const subChildLabel =
    data.type === "array" && data.childType
      ? symbolData.find((x) => x.name === data.childType).label
      : null;

  const findAndAddNode = (nodes, parentId) => {
    let newId;
    const updatedNode = nodes.map((node) => {
      if (node.id === parentId) {
        // Add a new node to the subChild array of the found parent node
        const newNode = {
          id: `${parentId}-${node.subChild ? node.subChild.length + 1 : 1}`,
          name: `New Node ${node.subChild ? node.subChild.length + 1 : 1}`,
          type: "string",
          transformationType: "constant",
        };
        newId = newNode.id;
        return {
          ...node,
          type: node.type === "array" ? node.type : "object",
          expanded: true,
          subChild: node.subChild ? [...node.subChild, newNode] : [newNode],
        };
      } else if (node.subChild && node.subChild.length > 0) {
        // Recursively search for the parent node in subChild arrays
        return {
          ...node,
          subChild: findAndAddNode(node.subChild, parentId).updatedNode,
        };
      }
      return node;
    });
    return { updatedNode, newId };
  };

  const findAndDeleteNode = (nodes, nodeId) => {
    return nodes.map((node) => {
      // Add a new node to the subChild array of the found parent node
      const nodeExist = node.subChild
        ? node.subChild.some((x) => x.id === nodeId)
        : false;
      if (nodeExist) {
        return {
          ...node,
          subChild: node.subChild.filter((x) => x.id !== nodeId),
        };
      } else if (node.subChild && node.subChild.length > 0) {
        // Recursively search for the parent node in subChild arrays
        return {
          ...node,
          subChild: findAndDeleteNode(node.subChild, nodeId),
        };
      }
      return node;
    });
  };

  const handleDeleteNode = (nodeId) => {
    // Find and delete the node from the state
    const updatedTreeData = findAndDeleteNode(jsonMapper.dest, nodeId);

    // Update the state with the modified data
    setJsonMapper((pre) => ({ ...pre, dest: updatedTreeData }));
  };

  const onNodeAdd = (parent) => {
    console.log(parent);
    // Find and update the parent node in the state
    const updatedTreeData = findAndAddNode(jsonMapper.dest, parent.id);
    // Update the state with the modified data
    setJsonMapper((pre) => ({ ...pre, dest: updatedTreeData.updatedNode }));
    setKey(new Date().getTime());
    treeRef.current.beginEdit(updatedTreeData.newId);
  };

  function updateNode(nodes, updatedNode) {
    return nodes.map((node) => {
      // Add a new node to the subChild array of the found parent node
      if (node.id === updatedNode.id) {
        return {
          ...updatedNode,
        };
      } else if (node.subChild && node.subChild.length > 0) {
        // Recursively search for the parent node in subChild arrays
        return {
          ...node,
          subChild: updateNode(node.subChild, updatedNode),
        };
      }
      return node;
    });
  }

  const onSave = (updatedNode) => {
    setJsonMapper((prevSchema) => ({
      ...prevSchema,
      dest: updateNode(prevSchema.dest, updatedNode),
    }));
    setOpenModel(false);
  };

  const onClose = () => {
    setOpenModel(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="flex justify-between items-center w-full pr-2 h-fit p-0 gap-1 text-white"
    >
      <div className="flex gap-1 items-center">
        <div className=" text-green-500 text-xs flex">
          <img src={dataSymbol?.symbol} className="h-4" />
          {dataSymbol?.label ? <sup>({dataSymbol?.label})</sup> : null}
          {subChildLabel ? <div>({subChildLabel})</div> : null}
        </div>
        <div className="flex text-black font-medium">
          {data.parentType === "array" ? (
            <div className="flex items-center">
              {data.parentName}&nbsp;
              <img src={parentSymbol?.symbol} className="h-4" />.{data.name}
            </div>
          ) : (
            data.name
          )}
          <Tooltip title={transormSymbol?.hoverText} trigger="hover">
            <img src={transormSymbol?.symbol} className="h-3" />
          </Tooltip>
        </div>
      </div>
      {templateType !== "source" && (isHovered || openModel) ? (
        <div className="flex gap-1">
          <button>
            <img
              onClick={() => onNodeAdd(data)}
              src="add-icon.svg"
              className="h-4 w-4"
            />
          </button>
          {data.id !== "root" ? (
            <Popover
              content={
                <PopoverContent
                  data={editingNode}
                  onSave={onSave}
                  onClose={onClose}
                />
              }
              title="Edit Node"
              trigger="click"
              destroyTooltipOnHide
              open={openModel}
              // onOpenChange={handleOpenChange}
            >
              <button>
                <img
                  onClick={() => {
                    const node = getNodeById(jsonMapper.dest, data.id);
                    setEditingNode(node);
                    setOpenModel(true);
                  }}
                  src="Edit-icon.svg"
                  className="h-4 w-4"
                />
              </button>
            </Popover>
          ) : null}
          {data.id !== "root" ? (
            <button onClick={() => handleDeleteNode(data.id)}>
              <img src="Delete-icon.svg" className="h-4 w-4" />
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default NodeTemplate;
