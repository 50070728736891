import React from "react";
import moment from "moment";
import { Select, Switch } from "antd";
import CustomTimePicker from "./CustomTimePicker";
import { useTranslation } from "react-i18next";
function InactiveAlert({ setAlertConfig, alertConfig, handleEmailChange }) {
  const { t } = useTranslation();
  return (
    <div className="space-y-2">
      <div className="">
        {/* <label className="">Interval:</label> */}
        <CustomTimePicker
          setAlertConfig={setAlertConfig}
          alertConfig={alertConfig}
        />
      </div>
      <div className="w-4/5">
        <label>{t(`form_labels.emails`)}:</label>
        <Select
          allowClear
          mode="tags"
          open={false}
          suffixIcon={null}
          style={{ width: "100%" }}
          placeholder={t(`form_labels.emails_placeholder`)}
          onChange={(value) => handleEmailChange(value)}
          value={alertConfig.alertData.emails}
          options={[]}
        />
      </div>
      <div className="space-x-2">
        <label>{t(`form_labels.active`)}:</label>
        <Switch
          className="bg-black"
          checked={alertConfig.active}
          onChange={(checked) =>
            setAlertConfig({ ...alertConfig, active: checked })
          }
        />
      </div>
    </div>
  );
}

export default InactiveAlert;
