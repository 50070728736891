import { Input, Modal, Switch, Table } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, Roles } from "../../../constants";
import AppContext from "../../../AppContext";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
const UserList = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("form_labels.name"),
      dataIndex: "name",
    },
    {
      title: t("form_labels.username"),
      dataIndex: "userName",
    },
    {
      title: t("form_labels.company"),
      dataIndex: "company",
      render: (company) => (company ? company : "Un Assigned"),
    },
    {
      title: t("form_labels.email"),
      dataIndex: "emailId",
    },
    {
      title: t("form_labels.role"),
      dataIndex: "role",
      render: (role) => Roles.find((x) => x.value === role).label,
    },
    {
      title: t("form_labels.status"),
      dataIndex: "active",
      render: (active) => (
        <span style={{ color: active ? "green" : "red" }}>
          {active ? t("form_labels.active") : t("form_labels.inactive")}
        </span>
      ),
    },
    {
      title: t("form_labels.action"),
      dataIndex: "id",
      render: (id) => (
        <button
          className="text-blue-500"
          onClick={() => navigate(`/userForm/${id}`)}
        >
          {t("buttons.edit")}
        </button>
      ),
    },
  ];
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { authCtx } = useContext(AppContext);
  const loadData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/User?companyId=${authCtx.profile.companyId}`
      );
      if (res.status === 200) {
        setDataList(res.data);
        setFilteredData(res.data);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSearch = (value) => {
    const filtered = dataList.filter(
      (item) =>
        item.userName.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.emailId.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-6">
      <div className="md:flex justify-between sm:items-center">
        <div className="md:flex w-full gap-4">
          <h1 className="md:text-xl  w-fit font-semibold leading-6 text-gray-900">
            {t("pages.users")}
          </h1>
          <Input.Search
            className="w-2/5"
            placeholder={t("pages.users_search_label")}
            onSearch={handleSearch}
          />
        </div>

        <div className="mt-4 flex gap-2 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() => navigate("/userForm/new")}
          >
            <PlusOutlined /> {t("form_labels.user")}
          </button>
        </div>
      </div>
      <div className="flex lg:my-3">
        <Table
          className="border w-full"
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          scroll={{
            y: "62vh",
          }}
        />
      </div>
    </div>
  );
};

export default UserList;
