import React, { useEffect, useState, useRef } from "react";
import { Spin } from "antd";
import { RadialGraph } from "@ant-design/graphs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BranchesOutlined,
  CodeOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { revertFormattedId } from "../../constants";
function AntdFlow({ data, addNode, timeSpan }) {
  const chartRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [FnData, setFnData] = useState({ nodes: [], edges: [] });
  const { t } = useTranslation();
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    nodeId: null,
    status: "all",
  });

  const showContextMenu = (x, y, nodeId, status) => {
    setContextMenu({ visible: true, x, y, nodeId, status });
  };

  const hideContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0, nodeId: null, status: "all" });
  };

  // In your right-click handler
  const handleRightClick = (nodeId, event, status) => {
    let state = status === "In progress" ? "all" : status;
    showContextMenu(event.canvasX, event.canvasY, nodeId, state);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (data) {
        const finalNodes = [
          { id: "esb", label: "ESB", name: "ESB", nodeType: "esb", status: "" },
        ];
        const finalEdges = [];
        data.forEach((integration) => {
          const integrationNode = {
            id: integration.id,
            label: integration.name,
            name: integration.description,
            status: integration.status,
            nodeType: "integration",
          };
          finalNodes.push(integrationNode);
          finalEdges.push({ source: "esb", target: integration.id });

          // integration.connections.forEach((connection) => {
          //   const connectionNode = {
          //     id: integration.id + "-" + connection.id,
          //     label: connection.id,
          //     name: connection.name,
          //     nodeType: connection.type,
          //   };
          //   finalNodes.push(connectionNode);
          //   finalEdges.push({
          //     source: integration.id,
          //     target: integration.id + "-" + connection.id,
          //   });
          // });
        });

        setNodes(finalNodes);
        setEdges(finalEdges);
        setFnData({ nodes: finalNodes, edges: finalEdges });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const asyncData = (node) => {
    const newNodes = [...nodes];
    const newEdges = [...edges];
    const nodeId = node.id;
    console.log(nodeId, "nodeId");
    // Find integration that matches the nodeId
    const integration = data.find((item) => item.id === nodeId);

    if (integration) {
      integration.connections.forEach((connection) => {
        if (!nodes.some((n) => n.id === connection.id)) {
          const connectionNode = {
            id: connection.id,
            label: connection.name,
            name: connection.appId,
            status: integration.status,
            nodeType: connection.type,
          };
          newNodes.push(connectionNode);

          if (connection.type === "Source") {
            newEdges.push({ source: connection.id, target: nodeId });
          } else if (connection.type === "Destination") {
            newEdges.push({ source: nodeId, target: connection.id });
          }
        }
      });

      // setNodes(newNodes);
      // setEdges(newEdges);
      // setFnData({ nodes: newNodes, edges: newEdges });
      return {
        nodes: newNodes,
        edges: newEdges,
      };
    }
  };

  useEffect(() => {
    fetchData();
  }, [data]);
  const getNodeStyle = (node) => {
    if (node.nodeType === "esb") {
      return { fill: "#b3eeff", stroke: "#b3eeff", lineWidth: 18 };
    }
    //  else if (node.nodeType === "Source") {
    //   return { fill: "#ede461", stroke: "#ede461" };
    // } else if (node.nodeType === "Destination") {
    //   return { fill: "#d160cf", stroke: "#d160cf" };
    // }
    else if (node.status === "Fail") {
      return { fill: "#f04c43", stroke: "#f04c43" };
    } else if (node.status === "Success") {
      return { fill: "#6bed5f", stroke: "#6bed5f" };
    } else {
      return { fill: "#59dbff", stroke: "#59dbff" };
    }
  };
  const getNodeLabel = (node) => {
    const text = node.label || "";
    const maxLineLength = 6; // Maximum characters per line
    const lines = text.match(new RegExp(`.{1,${maxLineLength}}`, "g")) || [
      text,
    ];
    return lines.join("\n");
  };
  const config = {
    data: FnData,
    autoFit: false,
    height: Math.max(600, window.innerHeight - 212),
    layout: {
      unitRadius: 80,
      nodeSpacing: 50,
      preventOverlap: true,
      strictRadial: true,
    },
    nodeCfg: {
      asyncData,
      size: 48,
      padding: 200,
      style: (node) => getNodeStyle(node),
      labelCfg: {
        style: {
          fontSize: 6,
          fill: "#000",
        },
        content: (node) => getNodeLabel(node),
      },
      nodeStateStyles: {
        hover: {
          stroke: "#0B5396",
          lineWidth: 2,
        },
      },
    },
    tooltipCfg: {
      show: true,
      customContent: (item) => <span>{item.name}</span>,
    },
    edgeCfg: {
      type: "cubic",
      style: () => {
        const lineWidth = 1;
        const stroke = "#0B5396";
        return {
          lineWidth,
          stroke,
        };
      },
      edgeStateStyles: {
        hover: {
          stroke: "#0B5396",
          lineWidth: 2,
        },
      },
      endArrow: (node) => {
        if (node.nodeType === "Destination") {
          return {
            fill: "#0B5396",
            d: 0,
            size: 0,
          };
        }
        return {
          fill: "#0B5396",
          d: 18,
          size: 4,
        };
      },
      startArrow: (node) => {
        if (node.nodeType !== "Source") {
          return {
            fill: "#0B5396",
            d: 0,
            size: 0,
          };
        }
        // return {
        //   fill: "#0B5396",
        //   d: 18,
        //   size: 4,
        // };
      },
    },
    behaviors: ["drag-canvas", "zoom-canvas", "drag-node"],
    onReady: (graph) => {
      chartRef.current = graph;
      graph.zoom(1.5);
      graph.on("node:contextmenu", (event) => {
        event.preventDefault();
        const nodeId = event.item._cfg.id;
        const status = event.item._cfg.model.status;
        handleRightClick(nodeId, event, status);
      });
    },
  };
  const handleOpenCatalog = () => {
    const targetUrl = `#/integration/${revertFormattedId(contextMenu.nodeId)}`;
    window.open(targetUrl, "_blank"); // '_blank' opens in a new tab or window
  };
  const handleOpenLogs = () => {
    const targetUrl = `#/logList/${contextMenu.nodeId}/${contextMenu.status}/${timeSpan}`;
    window.open(targetUrl, "_blank"); // '_blank' opens in a new tab or window
  };
  return (
    <div className="w-full relative h-full grow-0 overflow-hidden">
      <div className="flex cursor-default text-sm flex-col absolute left-5 top-20 z-[100]">
        <div className="flex gap-2 items-center">
          <div className="bg-green-400 w-3 h-3 rounded-full" />
          <div>{t("form_labels.success")}</div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="bg-red-400 w-3 h-3 rounded-full" />
          <div>{t("form_labels.fail")}</div>
        </div>

        <div className="flex gap-2 items-center">
          <div className="bg-cyan-400 w-3 h-3 rounded-full" />
          <div>{t("form_labels.no_message")}</div>
        </div>
      </div>
      {contextMenu.visible && (
        <div
          onMouseLeave={() => hideContextMenu()}
          id="menu"
          style={{
            position: "absolute",
            // top: "206px",
            // left: "737px",
            top: `${contextMenu.y - 30}px`,
            left: `${100 + contextMenu.x}px`,
            zIndex: "9999",
          }}
          className="context-menu"
        >
          <ul class="origin-top-right absolute top-full left-1/2 -translate-x-1/2 min-w-[240px] bg-white border border-slate-200 p-2 rounded-lg shadow-xl [&[x-cloak]]:hidden">
            <li onClick={() => addNode(contextMenu.nodeId)}>
              <a className="text-slate-800 cursor-pointer hover:bg-slate-50 flex items-center p-2">
                <div class="flex items-center justify-center bg-white border border-slate-200 rounded shadow-sm h-7 w-7 shrink-0 mr-3">
                  <ControlOutlined />
                </div>
                <span class="whitespace-nowrap">Quick View</span>
              </a>
            </li>
            <li onClick={() => handleOpenLogs()}>
              <a className="text-slate-800 cursor-pointer hover:bg-slate-50 flex items-center p-2">
                <div class="flex items-center justify-center bg-white border border-slate-200 rounded shadow-sm h-7 w-7 shrink-0 mr-3">
                  <CodeOutlined />
                </div>
                <span class="whitespace-nowrap">Go to Logs</span>
              </a>
            </li>
            <li onClick={() => handleOpenCatalog()}>
              <a class="text-slate-800 cursor-pointer hover:bg-slate-50 flex items-center p-2">
                <div class="flex items-center justify-center bg-white border border-slate-200 rounded shadow-sm h-7 w-7 shrink-0 mr-3">
                  <BranchesOutlined />
                </div>
                <span class="whitespace-nowrap">Go to Catalog</span>
              </a>
            </li>
          </ul>
        </div>
      )}
      <Spin
        spinning={loading}
        size="large"
        tip="Loading..."
        className="z-50 absolute top-1/2 left-1/2 text-blue-500 -translate-x-1/2 -translate-y-1/2"
      />
      <Graph config={config} nodes={nodes} />
    </div>
  );
}

export default AntdFlow;

const Graph = React.memo(
  ({ config, nodes }) => {
    // Only re-render when nodes change
    return <>{nodes.length > 0 && <RadialGraph {...config} />}</>;
  },
  (prevProps, nextProps) => {
    // Only re-render if nodes array changes
    return prevProps.nodes === nextProps.nodes;
  }
);
