import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BASE_FUN_URL, BASE_URL, generateFormattedId } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, Select, Table, Tag, Tooltip, Timeline, Modal } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import ReactJson from "react-json-view";
import { FileSearchOutlined } from "@ant-design/icons";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

const LogsList = () => {
  const { t } = useTranslation();
  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("YYYY-MM-DD hh:mm A");
  };
  const onTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);

    // Re-fetch data with new pagination settings
    handleSearch(
      integrationId,
      dateRange,
      integrationIds,
      status,
      pagination.current,
      pagination.pageSize
    );
  };
  const columns = [
    {
      // width: 150,
      title: t("form_labels.integration_id"),
      dataIndex: "integrationId",
      key: "integrationId",
      render: (text, record) => (
        <span>
          {record.integrationId}
          <br />
          <Tooltip
            className="text-xs text-gray-600 truncate max-w-10"
            placement="topLeft"
            title={record.correlationId}
          >
            {record.correlationId.length > 12
              ? record.correlationId.slice(0, 12) + "..."
              : record.correlationId}
          </Tooltip>
        </span>
      ),
    },
    {
      // width: 120,
      title: t("form_labels.source"),
      dataIndex: "source",
      key: "source",
    },
    {
      // width: 120,
      title: t("form_labels.destination"),
      dataIndex: "destination",
      key: "destination",
    },
    {
      // width: 200,
      title: t("form_labels.integration_name"),
      dataIndex: "integrationName",
      key: "integrationName",
    },
    {
      // width: 200,
      title: t("form_labels.message"),
      dataIndex: "message",
      key: "message",
      ellipsis: {
        showTitle: false,
      },
      render: (message) => (
        <Tooltip placement="topLeft" title={message}>
          {message}
        </Tooltip>
      ),
    },
    {
      // width: 210,
      title: t("form_labels.time"),
      dataIndex: "time",
      key: "time",
      render: (time) => <span>{formatDate(time)}</span>,
    },
    {
      width: 100,
      title: t("form_labels.status"),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Success" ? "green" : "red"}>{status}</Tag>
      ),
    },
    {
      title: "",
      dataIndex: "logs",
      key: "logs",
      render: (logs) => {
        const hasPayload = logs && logs.some((log) => log.status === "Fail");

        return (
          hasPayload && (
            <Tag
              // color="white"
              className="cursor-pointer hover:bg-gray-200"
              onClick={
                () =>
                  handleOpenModal(
                    logs.find((log) => log?.payload != null)?.payload
                  )
                // handleOpenModal(samplePayload)
              }
            >
              <FileSearchOutlined classID="w-8 h-8" />
            </Tag>
          )
        );
      },
    },
  ];

  const items = [
    { value: "all", label: "All" },
    {
      value: "Success",
      label: "Success",
    },
    {
      value: "Fail",
      label: "Failure",
    },
  ];

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // default page size
  const [totalRecords, setTotalRecords] = useState(0);
  const { catalogId, state, timeFrame } = useParams();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState();
  const [integrationId, setIntegrationId] = useState("all");
  const [integrationIds, setIntegrationIds] = useState([
    {
      value: "all",
      label: "All Integrations",
    },
  ]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [status, setStatus] = useState(items[0].value);
  const [jsonData, setJsonData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { authCtx } = useContext(AppContext);

  // Function to open the modal
  const handleOpenModal = (json) => {
    try {
      const payloadJson = JSON.parse(json);
      setModalVisible(true);
      setJsonData(payloadJson);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  function formatTime(date) {
    console.log(date, "date");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    return `${day}-${months[monthIndex]}-${year} ${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${ampm}`;
  }
  const onDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const getLast24HoursRange = () => {
    const endDate = new Date(); // Current date and time
    const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours

    return [dayjs(startDate), dayjs(endDate)];
  };
  function getTimeFrameRange(timeFrame) {
    const today = moment();
    let startDate,
      endDate = moment(today);

    if (timeFrame === "Today") {
      startDate = moment(today).startOf("day");
    } else if (timeFrame === "Week") {
      startDate = moment(today).startOf("week");
    } else if (timeFrame === "Month") {
      startDate = moment(today).startOf("month");
    }

    return [startDate, endDate];
  }
  const fetchApis = async (dateR) => {
    // await fetchIntegrations();
    const integrationData = await lookupIntegrationIds();
    if (catalogId === "all") await fetchData(integrationData);
    else {
      setIntegrationId(catalogId);
      setStatus("all");
      if (timeFrame) {
        const timeRange = getTimeFrameRange(timeFrame);
        setDateRange(timeRange);
        await handleSearch(
          catalogId,
          timeRange,
          integrationData,
          state,
          currentPage,
          pageSize
        );
      } else {
        await handleSearch(
          catalogId,
          dateR,
          integrationData,
          state,
          currentPage,
          pageSize
        );
      }
    }
  };
  useEffect(() => {
    const dateR = getLast24HoursRange();
    setDateRange(dateR);
    fetchApis(dateR);
  }, []);

  const lookupIntegrationIds = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Lookup/Integrations`);
      // Map the response data to label and value structure
      const mappedIntegrationIds = response.data.map((integration) => ({
        label: integration.id,
        value: integration.id.toLocaleLowerCase(),
        name: integration.name,
      }));
      setIntegrationIds((prevIntegrations) => [
        ...prevIntegrations,
        ...mappedIntegrationIds,
      ]);
      return mappedIntegrationIds;
    } catch (error) {
      console.error("Error fetching integration:", error);
    }
  };

  const fetchData = async (integrationData) => {
    setLoading(true);
    const dateR = getLast24HoursRange();
    const [startDate, endDate] = dateR;
    try {
      const response = await axios.get(`${BASE_URL}/api/Logs/filtered`, {
        params: {
          integrationId: catalogId.toLocaleLowerCase(),
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          status: "all", // Assuming you only want to search by one status for now
          companyId: authCtx.profile.companyId,
          pageNumber: 1,
          pageSize: pageSize,
        },
      });
      setTotalRecords(response.data.totalCount);
      const formattedData = response.data.data.map((group) => {
        const latestLog = group.logs[group.logs.length - 1];
        const integrationName =
          integrationData.find(
            (integration) => integration.value === latestLog.integrationId
          )?.name || "Unknown";
        return {
          key: group.correlationId,
          correlationId: group.correlationId,
          source: latestLog.source?.name,
          destination: latestLog.destination?.name,
          integrationId: latestLog.integrationId.toLocaleUpperCase(),
          integrationName: integrationName,
          message: latestLog.message,
          time: latestLog.time,
          status: latestLog.status,
          logs: group.logs,
          // logs: group.logs.slice(1), // Exclude the first log entry
        };
      });
      formattedData.sort((a, b) => new Date(b.time) - new Date(a.time));
      setDataList(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (
    catalogId,
    dateR,
    integrationData,
    currentStatus,
    page,
    pageSize
  ) => {
    setLoading(true);
    try {
      console.log(dateRange, "timesrange");
      const [startDate, endDate] = dateR;
      console.log(startDate, endDate, "timesrange");

      const response = await axios.get(`${BASE_URL}/api/Logs/filtered`, {
        params: {
          integrationId: catalogId.toLocaleLowerCase(),
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          status: currentStatus, // Assuming you only want to search by one status for now
          companyId: authCtx.profile.companyId,
          pageNumber: page,
          pageSize,
        },
      });
      const formattedData = response.data.data.map((group) => {
        const latestLog = group.logs[group.logs.length - 1];
        const integrationName =
          integrationData.find(
            (integration) => integration.value === latestLog.integrationId
          )?.name || "Unknown";
        return {
          key: group.correlationId,
          correlationId: group.correlationId,
          source: latestLog.source?.name,
          destination: latestLog.destination?.name,
          integrationId: latestLog.integrationId.toLocaleUpperCase(),
          integrationName: integrationName,
          message: latestLog.message,
          time: latestLog.time,
          status: latestLog.status,
          logs: group.logs,
          // logs: group.logs.slice(1), // Exclude the first log entry
        };
      });
      formattedData.sort((a, b) => new Date(b.time) - new Date(a.time));
      setDataList(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const expandedRowRender = (record) => (
    <Timeline mode="left" className="flex flex-col items-start">
      {record.logs.map((log, index) => (
        <Timeline.Item
          label={formatDate(log.time)}
          children={log.message}
          key={index}
          color={
            log.status === "Success"
              ? "green"
              : log.status === "Processing"
              ? "orange"
              : "red"
          }
        >
          {/* <p>{formatDate(log.time)}</p> */}
          <p className="w-[300px]">{log.message}</p>
          {/* {index === 0 && log.source && (
            <p>
              {log.source?.name} To {log.destination?.name}
            </p>
          )} */}
        </Timeline.Item>
      ))}
    </Timeline>
  );

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.correlationId]);
    } else {
      setExpandedRowKeys(
        expandedRowKeys.filter((key) => key !== record.correlationId)
      );
    }
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-6 ">
      <div className="md:flex justify-between sm:items-center">
        <div className="md:flex w-full gap-4">
          <h1 className="md:text-xl  w-fit font-semibold leading-6 text-gray-900">
            {t("pages.logs")}
          </h1>
          <Select
            defaultValue="1"
            value={integrationId}
            className="w-2/6 h-8"
            // mode="multiple"
            onChange={(e) => setIntegrationId(e)}
            options={integrationIds}
          />
          <RangePicker
            value={dateRange}
            showTime // This adds time selection to the date picker
            format="YYYY-MM-DD HH:mm:ss"
            className="h-8"
            onChange={onDateRangeChange}
          />
          <Select
            defaultValue="1"
            value={status}
            // mode="multiple"
            tagRender={tagRender}
            onChange={(e) => setStatus(e)}
            // style={{ width: 120 }}
            className="h-8 w-24"
            options={items}
          />
          <button
            type="button"
            className="items-center border h-8 gap-2 flex rounded-md py-2 bg-white-600 px-3 text-center text-sm font-semibold text-black shadow-sm hover:bg-gray-100 outline-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            onClick={() =>
              handleSearch(
                integrationId,
                dateRange,
                integrationIds,
                status,
                currentPage,
                pageSize
              )
            }
          >
            <svg
              className="h-4 w-4"
              aria-labelledby="title desc"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.9 19.7"
            >
              <g class="search-path" fill="none" stroke="#848F91">
                <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                <circle cx="8" cy="8" r="7" />
              </g>
            </svg>
            {t("buttons.search")}
          </button>
        </div>
      </div>
      <div className="flex lg:my-3 ">
        <Table
          className="w-full border rounded"
          columns={columns}
          dataSource={dataList}
          expandable={{
            expandedRowRender: expandedRowRender,
            rowExpandable: (record) => record.logs.length > 0, // Specify condition for expandability
            // onExpand: onExpand,
            // expandedRowKeys: expandedRowKeys,
          }}
          // expandable={{
          //   expandedRowRender: expandedRowRender,
          //   onExpand: onExpand,
          //   expandedRowKeys: expandedRowKeys,
          // }}
          pagination={{
            current: currentPage,
            pageSize,
            total: totalRecords,
            showSizeChanger: true, // Allow users to change page size
          }}
          onChange={onTableChange}
          scroll={{
            y: "62vh",
          }}
        />
      </div>
      <Modal
        title="Payload"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={800} // Adjust width as per your requirement
      >
        <ReactJson src={jsonData} theme="bright" />
      </Modal>
    </div>
  );
};

export default LogsList;
