import React from "react";
import { useNavigate } from "react-router-dom";
import AppLogo from "../AppLogo";
import LanguageSwitcher from "../LanguageSwitcher";

const Authlayout = ({ children }) => {
  const navigate = useNavigate();
  const onClickLogo = () => {
    navigate("/");
  };
  return (
    <div className="relative">
      <div className="bg-[rgb(4,9,31)] h-screen w-screen overflow-hidden">
        <div className="absolute h-fit w-full sm:block hidden bg-[rgb(4,9,31)] bg-opacity-0 mb-20 overflow-hidden z-10">
          <div className="px-7 py-3 flex gap-3 items-center h-14 w-full">
            <button
              type="button"
              className="h-9 pr-1 flex gap-3 items-center cursor-pointer"
              onClick={onClickLogo}
            >
              <AppLogo />
            </button>
          </div>
        </div>
        {children}
      </div>
      <div className="absolute z-[9999] invert top-2 right-28">
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Authlayout;
