import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, CleanKey } from "../../constants";
import AdminPanel from "./AdminPanel";
import { Select, Drawer, Descriptions } from "antd";
import IntegrationDiagram from "./D2Flow";
import AntdFlow from "./AntdFlow";
import moment from "moment";
import LogDetails from "./LogDetails";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
const Monitoring = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [timeSpan, setTimeSpan] = useState("Today");
  const [integration, setIntegration] = useState("all");
  const [selectedNode, setSelectedNode] = useState("GDIN-0001");
  const [openedNodes, setOpenedNodes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [integrationData, setIntegrationData] = useState([]);
  const { authCtx } = useContext(AppContext);
  const [integrations, setIntegrations] = useState([
    {
      value: "all",
      label: t("dashboard.integration_selector.label"),
    },
  ]);
  const getIntegrationStatus = () => {
    let status = integrationData.find((i) => i.id === selectedNode)?.status;
    if (status === "In progress") {
      status = "all";
    }
    return status;
  };
  function getTimeFrameRange(timeFrame) {
    const today = moment();
    let startTime, endTime;

    if (timeFrame === "Today") {
      startTime = moment(today).startOf("day");
      endTime = moment(today).endOf("day");
    } else if (timeFrame === "Week") {
      startTime = moment(today).startOf("week");
      endTime = moment(startTime).add(1, "week").endOf("week");
    } else if (timeFrame === "Month") {
      startTime = moment(today).startOf("month");
      endTime = moment(startTime).endOf("month");
    }

    return { startTime, endTime };
  }
  const lookupApi = async () => {
    try {
      const { startTime, endTime } = getTimeFrameRange(timeSpan);
      const response = await axios.get(`${BASE_URL}/api/Lookup/dashboardInfo`, {
        params: {
          startTime: startTime.toISOString(), // Convert to ISO format to ensure proper formatting
          endTime: endTime.toISOString(), // Convert to ISO format to ensure proper formatting
          integration: integration,
          companyId: authCtx.profile.companyId,
        },
      });
      setData({
        ...response.data,
        logStatus: response.data.logStatus.map((x) => ({
          ...x,
          time: new Date(x.time),
        })),
      });
      fetchData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async (data) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/IntegrationItems?companyId=${authCtx.profile.companyId}`
      );
      if (res.status === 200) {
        const intData = res.data;
        console.log(intData, "intData");
        // Transformation logic
        const integrationSamples = intData.map((item, index) => {
          const statusObj = data.messageStatsByIntegrationId?.find(
            (stats) =>
              stats.integrationId === item.readableId.toLocaleLowerCase()
          );
          const status = statusObj ? statusObj.status : "In progress"; // Default to "in progress" if status data not found
          const connections = [
            {
              id: "SRC-" + ("000" + item.id).slice(-3),
              appId: item.source.systemId,
              name: item.source.name,
              iconUrl: item.source.iconUrl,
              type: "Source",
            },
            ...item.destinations.map((i) => ({
              id: "DEST-" + ("000" + item.id).slice(-3),
              appId: i.systemId,
              name: i.name,
              iconUrl: i.iconUrl,
              type: "Destination",
            })),
          ];
          return {
            id: item.readableId,
            name: item.name,
            status: status,
            description: item.description,
            connections: connections,
          };
        });
        console.log(integrationSamples, "integrations");
        // Once you have the transformed data, you can use it as needed
        setIntegrationData(integrationSamples);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchIntegrations = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Lookup/Integrations`);
      const integrations = response.data.map((integration) => ({
        label: integration.name,
        value: integration.id.toLocaleLowerCase(),
      }));
      // Update state by concatenating the new integrations array
      setIntegrations((prevIntegrations) => [
        ...prevIntegrations,
        ...integrations,
      ]);
    } catch (error) {
      console.error("Error fetching integrations:", error);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);
  useEffect(() => {
    lookupApi();
  }, [timeSpan, integration, authCtx.profile.companyId]);

  const addNode = (node) => {
    console.log(openedNodes, "nodes");
    if (!openedNodes.includes(node)) {
      // setOpenedNodes([...openedNodes, node]);
      showDrawer(node); // Open drawer if node was added
    } else {
      showDrawer(node);
    }
    console.log(openedNodes, "openNodes");
  };

  const handlePointDoubleClick = async (args) => {
    // Retrieve the X value of the clicked point
    const timeStamp = new Date(args.point.x).getTime(); // Convert to milliseconds
    const status = args.series.properties.name;
    let dataIndex = data.logStatus.findIndex(
      (i) => new Date(i.time).getTime() === timeStamp
    );
    let startDate = data.logStatus[dataIndex].time;
    let endDate = data.logStatus[dataIndex + 1]?.time;
    if (!endDate) {
      endDate = moment(startDate).endOf("month");
    }
    try {
      let response = await axios.get(`${BASE_URL}/api/Logs/filtered`, {
        params: {
          integrationId: integration,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          status: status,
          companyId: authCtx.profile.companyId,
        },
      });
      const formattedData = response.data.data.map((group) => {
        const latestLog = group.logs[group.logs.length - 1];
        const integrationName =
          integrationData.find(
            (integration) => integration.value === latestLog.integrationId
          )?.name || "Unknown";
        return {
          key: group.correlationId,
          correlationId: group.correlationId,
          integrationId: latestLog.integrationId.toLocaleUpperCase(),
          integrationName: integrationName,
          message: latestLog.message,
          time: latestLog.time,
          status: latestLog.status,
          logs: group.logs,
          // logs: group.logs.slice(1), // Exclude the first log entry
        };
      });
      formattedData.sort((a, b) => new Date(b.time) - new Date(a.time));
      setLogs(formattedData);
      console.log(formattedData, "fmData");
      setSelectedNode("");
      setOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setLoading(false);
    }
  };
  const monitorModes = [
    // { name: "OVERVIEW", id: 1, component: <OverView data={data} /> },
    {
      name: "OVERVIEW",
      id: 1,
      component: (
        <AdminPanel
          handlePointDoubleClick={handlePointDoubleClick}
          data={data}
          timeStamp={timeSpan}
          t={t}
        />
      ),
    },
    // { name: "DETAILED", id: 2, component: <SyncfusionFlow /> },
    // {
    //   name: "DETAILED",
    //   id: 2,
    //   component: (
    //     <IntegrationDiagram addNode={addNode} integrations={integrationData} />
    //   ),
    // },
    {
      name: "DETAILED",
      id: 3,
      component: (
        <AntdFlow
          data={integrationData}
          addNode={addNode}
          timeSpan={timeSpan}
        />
      ),
    },
  ];
  const [selectedMode, setSelectedMode] = useState(monitorModes[0]);

  const handleTimeSpanChange = (newTimeSpan) => {
    setTimeSpan(newTimeSpan);
  };
  const showDrawer = (node) => {
    setSelectedNode(node);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const removeNode = (node) => {
    setOpenedNodes(openedNodes.filter((n) => n !== node));
    // onClose(); // Close drawer when node is removed
  };
  const timeStamps = ["Today", "Week", "Month"];
  return (
    <div className="h-full w-full">
      <div className="z-50 flex top-5 right-10 space-x-2 absolute">
        <Select
          defaultValue="1"
          value={integration}
          className="w-2/6 h-12"
          // mode="multiple"
          onChange={(e) => setIntegration(e)}
          options={integrations}
        />
        <div
          className="w-[330px] flex items-center rounded group border bg-white border-blue-950 list-none bg-blue-gray-50/60"
          role="list"
        >
          {timeStamps.map((item, index) => (
            <li
              key={item}
              className={`z-30 flex justify-center w-full h-full items-center text-center border-blue-950 ${
                index === 0 ? "" : "border-l"
              } ${
                timeSpan === item
                  ? "bg-blue-950 text-white"
                  : "hover:bg-cyan-100"
              }`}
              onClick={() => handleTimeSpanChange(item)}
              role="tab"
              aria-selected={timeSpan === item}
            >
              <div className="z-30 flex items-center justify-center w-full px-0 py-3 transition-all ease-in-out border-0 rounded-lg cursor-pointer bg-inherit">
                <span className="ml-1 text-sm">
                  {t(`dashboard.tabs.${CleanKey(item)}`)}
                </span>
              </div>
            </li>
          ))}
        </div>

        <div
          className={`inline-flex rounded group border min-w-max border-blue-950`}
        >
          {monitorModes.map((tm, index) => (
            <button
              type="button"
              className={` md:text-xs text-xxs font-medium text-center px-6 py-4   ${
                selectedMode.id === tm.id
                  ? "bg-blue-950 text-white"
                  : "text-black bg-white"
              } ${
                index === 0 ? "rounded-tl rounded-bl" : "rounded-tr rounded-br"
              }`}
              onClick={() => setSelectedMode(tm)}
              // disabled={disabled}
              key={tm.id}
            >
              {t(`dashboard.view_options.${CleanKey(tm.name)}`)}
            </button>
          ))}
        </div>
      </div>
      <div className="z-50 flex top-20 right-10 space-x-2 absolute">
        <div className={`inline-flex rounded group border border-blue-950`}>
          {openedNodes.map((tm, index) => (
            <div className="flex items-center">
              <button
                type="button"
                className={`flex gap-2 md:text-xs text-xxs font-medium text-center px-2 py-2   ${
                  selectedNode === tm
                    ? "bg-blue-950 text-white"
                    : "text-black bg-white"
                } ${index === 0 ? "rounded-tl rounded-bl" : " border-l-[2px]"}`}
                onClick={() => showDrawer(tm)}
                // disabled={disabled}
                key={index}
              >
                {tm}{" "}
              </button>{" "}
              <img
                onClick={() => removeNode(tm)}
                src="Delete-icon.svg"
                alt=""
                className={`w-4 h-full cursor-pointer pr-1 ${
                  selectedNode === tm ? "bg-blue-950 " : "text-black "
                }`}
              />
            </div>
          ))}
        </div>
      </div>
      {monitorModes.find((x) => x.id === selectedMode.id)?.component}

      <Drawer
        title={selectedNode}
        placement="right"
        closable={false}
        size="large"
        onClose={onClose}
        open={open}
        getContainer={false}
      >
        <LogDetails
          timeSpan={timeSpan}
          status={getIntegrationStatus()}
          catalogId={selectedNode}
          data={logs}
        />
      </Drawer>
    </div>
  );
};

export default Monitoring;
