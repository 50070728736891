import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BASE_URL } from "./constants";

const AppContext = React.createContext({
  profile: null,
  company: null,
  setCompany: () => {},
  companies: [],
  isLoggedIn: false,
  isLoading: false,
  login: (profile) => {},
  logout: () => {},
});

export const AppContextProvider = (props) => {
  const [company, setCompany] = useState([]);
  const [profile, setProfile] = useState(() => {
    const profileString = localStorage.getItem("gd_hat_profile");
    return profileString ? JSON.parse(profileString) : null;
  });
  const [loading, setLoading] = useState(false);
  const getUser = async (email) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL}/api/Auth/SingleSignOn?email=${email}`
      );
      if (res.data.active) {
        loginHandler(res.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };
  const checkToken = async () => {
    try {
      let token = gapi.auth?.getToken();
      let userProfile = getCookie("GDuserProfile");
      if (!profile?.token && token && token.id_token && token.access_token) {
        const accessToken = token.id_token;
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken.email) {
          getUser(decodedToken.email);
        }
      } else if (!profile?.token && userProfile) {
        const decodedToken = jwtDecode(userProfile);
        if (decodedToken.email) {
          await getUser(decodedToken.email);
        }
      }
    } catch (error) {
      console.error("Error checking token:", error);
    }
  };
  useEffect(() => {
    checkToken();
    // fetchCompanies();
  }, []);
  // useEffect(() => {
  //   console.log(profile);
  //   if (profile !== null) {
  //     console.log(profile);
  //     const updatedProfile = {
  //       ...profile,
  //       company: company.label,
  //       companyId: company.value,
  //     };
  //     authCtx.login(updatedProfile);
  //   }
  // }, [profile]);
  const waitForToken = async () => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error("Token retrieval timed out"));
      }, 5000);

      const interval = setInterval(() => {
        const token = gapi.auth?.getToken();
        if (token) {
          clearInterval(interval);
          clearTimeout(timeout);
          resolve(token);
        }
      }, 100);
    });
  };

  const loginHandler = (userProfile) => {
    setProfile(userProfile);
    localStorage.setItem("gd_hat_profile", JSON.stringify(userProfile));
  };

  const logoutHandler = () => {
    setProfile(null);
    localStorage.removeItem("gd_hat_profile");
    document.cookie = "GDuserProfile=; Max-Age=-99999999;";
    window.location.href = "/";
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const authCtx = {
    profile: profile,
    isLoading: loading,
    isLoggedIn: !!profile?.token,
    login: loginHandler,
    logout: logoutHandler,
    company: company,
    setCompany: setCompany,
  };

  return (
    <AppContext.Provider value={{ authCtx }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
