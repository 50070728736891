import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend) // Load translations from a server or static files
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Integrate with React
  .init({
    fallbackLng: "en", // Fallback to English if the detected language isn't supported
    debug: true,
    interpolation: {
      escapeValue: false, // Not needed for React as it handles escaping by default
    },
  });

export default i18n;
