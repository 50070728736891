import { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { AppIconPath } from "../../../constants";
import { useTranslation } from "react-i18next";
import { CloseSquareOutlined } from "@ant-design/icons";
const handleStyle = { left: 10 };

function ChildCard({ data, isConnectable }) {
  const { t } = useTranslation();
  const [nodeData, setNodeData] = useState(data);
  const [appList, setAppList] = useState([]);

  useEffect(() => {
    // loadData();
    if (data.appList) setAppList(data.appList);
  }, [data?.appList]);

  return (
    <div
      className={`w-fit min-w-56 p-4 bg-white border border-l-8 ${
        data.type === "source" ? "border-lime-500" : "border-purple-600"
      }  rounded-lg shadow`}
    >
      {/* <div> */}
      <div className="flex justify-between items-center mb-3">
        <p className=" text-gray-600 font-medium text-lg">
          {data.type !== "source"
            ? t(`form_labels.destination`)
            : t(`form_labels.source`)}
        </p>
        <div className="flex gap-2">
          {data.editing ? (
            <button
              className="transition-all text-orange-600 ease-in-out pt-0.5 duration-200"
              onClick={() => data.onClose(data.nodeId)}
            >
              <CloseSquareOutlined className="h-4 w-4" />
            </button>
          ) : (
            // <button
            //   className="border-green-600 transition-all ease-in-out duration-200 border px-2 py-0 rounded"
            //   onClick={() => data.onSave(nodeData)}
            // >
            //   <img src="save-icon.svg" className="h-3 w-3" />
            // </button>
            <button
              className="transition-all ease-in-out duration-200"
              onClick={() => data.onEdit(data.nodeId)}
            >
              <img src="Edit-icon.svg" className="h-4 w-4" />
            </button>
          )}
          <button onClick={() => data.onDelete(data.nodeId)}>
            <img src="Delete-icon.svg" className="h-4 w-4" />
          </button>
        </div>
      </div>
      {data.editing ? (
        <div>
          <label
            for="countries"
            className="block mb-2 text-sm transition-all ease-in-out duration-200 font-medium text-gray-900 dark:text-white"
          >
            {t(`form_labels.select_app`)}
          </label>
          <select
            id="countries"
            value={nodeData.app?.id}
            name="app"
            onChange={(e) => {
              const updatedData = {
                ...nodeData,
                app: appList.find((x) => x.id === e.target.value),
              };
              data.onSave(updatedData);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg h-8 focus:ring-blue-500 focus:border-blue-500 block w-full px-1"
          >
            <option key="" value="">
              {t(`form_labels.select_app`)}
            </option>
            {appList?.map((x) => (
              <option key={x.id} value={x.id}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="transition-all ease-in-out duration-200">
          <label className="font-medium text-sm">
            {t(`form_labels.app_details`)} :
          </label>
          <div className="flex justify-between gap-2 items-center mt-2">
            {data?.app?.iconUrl && (
              <div className="font-medium " id={data.app.iconUrl}>
                <img
                  src={AppIconPath + data?.app?.iconUrl}
                  alt="Selected"
                  className="mt-2 w-12 h-12 object-cover"
                />
              </div>
            )}
            <div className="font-medium flex flex-col justify-between text-sm">
              <label className="text-xs text-slate-400">ID</label>
              <label>{data?.app?.appId}</label>
            </div>

            <div className="font-medium flex flex-col justify-between text-sm">
              <label className="text-xs text-slate-400">
                {t(`form_labels.name`)}
              </label>
              <label>{data?.app?.name}</label>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
      <Handle
        type={data.type !== "source" ? "target" : "source"}
        id={data.nodeId + "source"}
        position={data.type !== "source" ? Position.Left : Position.Right}
        // style={{ top: (index + 1) * 7 }}
      />
    </div>
  );
}

export default ChildCard;
