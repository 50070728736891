import axios from "axios";
import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AppIconPath, BASE_URL } from "../../../constants";
import { Switch } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import AppContext from "../../../AppContext";
import { useTranslation } from "react-i18next";

export const AppForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const defaultForm = {
    id: "new",
    appId: "",
    name: "",
    description: "",
    image: null,
    active: true,
  };
  const [app, setApp] = useState(defaultForm);
  const [companies, setCompanies] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { authCtx } = useContext(AppContext);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Company`);
      const formattedCompanies = response.data.map((company) => ({
        value: company.id,
        label: company.name,
        country: company.country,
      }));
      setCompanies(formattedCompanies);
      if (id === "new") {
        const selectedOption = formattedCompanies.find(
          (x) => x.value === authCtx.profile.companyId
        );
        setApp((prevState) => {
          return {
            ...prevState,
            companyId: selectedOption.value,
            company: selectedOption.label,
            country: selectedOption.country,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const onValueChange = (e) => {
    setApp((x) => ({ ...x, [e.target.name]: e.target.value }));
  };

  const onCancelClick = () => {
    navigate("/appList");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData = { AppItem: app };
    if (selectedImage) {
      formData = { ...formData, File: selectedImage };
    }
    setLoading(true);
    try {
      let res = await axios.post(`${BASE_URL}/api/App`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      toast.success("App Master saved successfully!");
      navigate(`/appForm/${res.data}`);
    } catch (err) {
      setLoading(false);
      toast.error("An error occurred while saving App Master!");
    }
  };

  const handleDropdownChange = (fieldName) => (selectedOption) => {
    if (selectedOption !== undefined) {
      setApp((prevState) => {
        return {
          ...prevState,
          companyId: selectedOption.value,
          company: selectedOption.label,
          country: selectedOption.country,
        };
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const loadData = async () => {
    let res = await axios.get(`${BASE_URL}/api/App/GetById?Id=${id}`);
    if (res.status === 200) {
      setApp(res.data);
    }
  };

  useEffect(() => {
    if (id !== "new") loadData();
    fetchCompanies();
  }, []);
  const handleSwitchChange = (fieldName) => (checked) => {
    setApp((prevState) => ({
      ...prevState,
      [fieldName]: checked,
    }));
  };
  const removeImage = () => {
    setSelectedImage(null);
    setApp((prevState) => ({
      ...prevState,
      iconUrl: null,
    }));
  };
  return (
    <div className="px-7 py-5 bg-white w-full h-screen">
      <h2
        onClick={onCancelClick}
        className=" cursor-pointer mb-2 text-md text-blue-600 font-semibold"
      >
        {t("pages.apps")}
        <span className="text-black">
          {" "}
          / {id === "new" ? t("buttons.new") : t("buttons.update")} App
        </span>
      </h2>
      <form noValidate className="">
        <div className="gap-4 justify-between w-3/5 flex">
          <div className="grid grid-cols-1 w-full items-center gap-4 mt-2">
            <div className=" ">
              <p className="text-sm font-medium">{t("form_labels.app_id")}</p>
              <input
                type="text"
                name="appId"
                value={app?.appId}
                onChange={onValueChange}
                placeholder={t("form_labels.app_id")}
                className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
              />
              {isSubmitted && !app?.appId && (
                <p className="text-red-700 mt-1 text-xs font-semibold">
                  This field is required
                </p>
              )}
            </div>
            <div className="">
              <p className="text-sm font-medium">{t("form_labels.app_name")}</p>
              <input
                type="text"
                name="name"
                value={app?.name}
                onChange={onValueChange}
                placeholder={t("form_labels.name")}
                className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
              />
              {isSubmitted && !app?.name && (
                <p className="text-red-700 mt-1 text-xs font-semibold">
                  This field is required
                </p>
              )}
            </div>

            <div className="space-y-3">
              <div>
                <p className="text-sm font-medium">
                  {t("form_labels.description")}
                </p>
                <textarea
                  name="description"
                  value={app.description}
                  onChange={onValueChange}
                  placeholder={t("form_labels.description")}
                  className="block py-2 mt-2 w-full px-2 h-18 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
                />
                {isSubmitted && !app?.description && (
                  <p className="text-red-700 mt-1 text-xs font-semibold">
                    This field is required
                  </p>
                )}
              </div>
            </div>
            {authCtx.profile.role === 100 && (
              <div className="space-y-3">
                <p className="text-sm font-medium">
                  {t("form_labels.company")}
                </p>
                <div className="mt-2 h-8">
                  <Select
                    className="block mt-2 w-full  h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
                    options={companies}
                    value={
                      companies &&
                      companies.find(
                        (option) => option.value === app?.companyId
                      )
                    }
                    onChange={handleDropdownChange("company")}
                  />
                  {isSubmitted && !app?.companyId && (
                    <p className="text-red-700 mt-1 text-xs font-semibold">
                      This field is required
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mt-8 flex justify-end h-fit w-2/5 bg-grey-lighter">
            {!(selectedImage || app?.iconUrl) && (
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">
                  {t("buttons.select_icon")}
                </span>
                <input
                  type="file"
                  className="hidden"
                  onChange={handleImageChange}
                />
              </label>
            )}
            {(app?.iconUrl || selectedImage) && (
              <div className="relative w-fit flex flex-col gap-1">
                {selectedImage ? (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="mt-2 w-32 h-32 object-cover"
                  />
                ) : (
                  app?.iconUrl && (
                    <img
                      src={AppIconPath + app?.iconUrl}
                      alt="Selected"
                      className="mt-2 w-32 h-32 object-cover"
                    />
                  )
                )}
                <button
                  className="bg-red-500 h-8 mt-2 text-white p-1 rounded"
                  onClick={removeImage}
                >
                  {t("buttons.remove")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="justify-between items-end flex w-3/5 gap-2 mt-4">
          <div className="flex items-end h-full gap-2">
            <p className="text-sm font-medium">{t("form_labels.status")} :</p>
            <Switch
              checkedChildren={t("form_labels.active")}
              unCheckedChildren={t("form_labels.inactive")}
              className="bg-neutral-900"
              // className={user.active ? "active-switch" : "inactive-switch"}
              checked={app.active}
              onChange={handleSwitchChange("active")}
            />
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              className="block rounded-md bg-white px-3 py-2 text-center outline-blue-500 outline-[1px] outline text-sm font-semibold text-blue-600 hover:text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={onCancelClick}
            >
              {t("buttons.cancel")}
            </button>
            <button
              type="submit"
              className="block disabled:opacity-40 rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={onSubmit}
              disabled={loading}
            >
              {id === "new" ? t("buttons.create") : t("buttons.update")}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};
