import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AppIconPath, BASE_URL, Country } from "../../../constants";
import Select from "react-select";
import { Switch } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const CompanyForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const defaultForm = {
    id: null,
    code: "",
    legalName: "",
    name: "",
    country: null,
  };
  const [company, setCompany] = useState(defaultForm);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const onValueChange = (e) => {
    setCompany((x) => ({ ...x, [e.target.name]: e.target.value }));
  };

  const onCancelClick = () => {
    navigate("/companyList");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    setIsSubmitted(true);
    let formData = { CompanyItem: company };
    if (selectedImage) {
      formData = { ...formData, File: selectedImage };
    }
    if (form.checkValidity()) {
      setLoading(true);
      try {
        if (id === "new") {
          // If id is "new", make a POST request
          let res = await axios.post(`${BASE_URL}/api/Company`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setLoading(false);

          toast.success("Company Master created successfully!");
          navigate(`/companyForm/${res.data}`);
        } else {
          // If id is not "new", make a PUT request
          let res = await axios.put(`${BASE_URL}/api/Company`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setLoading(false);

          toast.success("Company Master updated successfully!");
          navigate(`/companyForm/${id}`);
        }
      } catch (err) {
        setLoading(false);

        toast.error("An error occured while updating Company Master!");
      }
    }
  };

  const handleSwitchChange = (fieldName) => (checked) => {
    setCompany((prevState) => ({
      ...prevState,
      [fieldName]: checked,
    }));
  };

  const loadData = async () => {
    let res = await axios.get(`${BASE_URL}/api/Company/GetById?Id=${id}`);
    if (res.status === 200) {
      setCompany(res.data);
    }
  };

  useEffect(() => {
    if (id !== "new") loadData();
  }, []);
  const handleDropdownChange = (fieldName) => (selectedOption) => {
    if (selectedOption !== undefined)
      setCompany((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value,
      }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removeImage = () => {
    setSelectedImage(null);
    setCompany((prevState) => ({
      ...prevState,
      iconUrl: null,
    }));
  };
  return (
    <div className="px-7 py-5 bg-white w-full h-screen">
      <h2
        onClick={onCancelClick}
        className=" cursor-pointer mb-2 text-mb text-blue-600 font-semibold"
      >
        {t("pages.companies")}
        <span className="text-black">
          {" "}
          / {id === "new" ? t("buttons.new") : t("buttons.new")}{" "}
          {t("form_labels.company")}
        </span>
      </h2>
      <form noValidate className="">
        <div className=" gap-4 flex">
          <div className="grid grid-cols-1 lg:w-3/5 items-center gap-4 mt-2">
            <div className="">
              <p className="text-sm font-medium">{t("form_labels.name")}</p>
              <input
                type="text"
                name="name"
                value={company?.name}
                onChange={onValueChange}
                placeholder="Name"
                className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
              />
              {isSubmitted && !company?.name && (
                <p className="text-red-700 mt-1 text-xs font-semibold">
                  This field is required
                </p>
              )}
            </div>
            <div className="">
              <p className="text-sm font-medium">{t("form_labels.code")}</p>
              <input
                type="text"
                name="code"
                value={company?.code}
                onChange={onValueChange}
                placeholder="Code"
                className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
              />
              {isSubmitted && !company?.code && (
                <p className="text-red-700 mt-1 text-xs font-semibold">
                  This field is required
                </p>
              )}
            </div>
            <div className=" ">
              <p className="text-sm font-medium">
                {t("form_labels.legal_name")}
              </p>
              <input
                type="text"
                name="legalName"
                value={company?.legalName}
                onChange={onValueChange}
                placeholder="Legal Name"
                className="block mt-2 w-full px-2 h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
              />
              {isSubmitted && !company?.legalName && (
                <p className="text-red-700 mt-1 text-xs font-semibold">
                  This field is required
                </p>
              )}
            </div>
            <div className="">
              <p className="text-sm font-medium">{t("form_labels.country")}</p>
              <div className="mt-2 h-8">
                <Select
                  options={Country}
                  value={
                    Country &&
                    Country.find((option) => option.value === company?.country)
                  }
                  className="block mt-2 w-full h-9 border placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
                  onChange={handleDropdownChange("country")}
                />
                {isSubmitted && !company?.country && (
                  <p className="text-red-700 mt-1 text-xs font-semibold">
                    This field is required
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-end h-full gap-2">
              <p className="text-sm font-medium">{t("form_labels.status")} :</p>
              <Switch
                checkedChildren={t("form_labels.active")}
                unCheckedChildren={t("form_labels.inactive")}
                className="bg-neutral-900"
                // className={user.active ? "active-switch" : "inactive-switch"}
                checked={company.active}
                onChange={handleSwitchChange("active")}
              />
            </div>
          </div>
          <div className="mt-8 w-full bg-grey-lighter">
            {!(selectedImage || company?.iconUrl) && (
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">
                  {t("buttons.select_icon")}
                </span>
                <input
                  type="file"
                  className="hidden"
                  onChange={handleImageChange}
                />
              </label>
            )}
            {(company?.iconUrl || selectedImage) && (
              <div className="relative w-fit flex flex-col gap-1">
                {selectedImage ? (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="mt-2 w-32 h-32 object-cover"
                  />
                ) : (
                  company?.iconUrl && (
                    <img
                      src={AppIconPath + company?.iconUrl}
                      alt="Selected"
                      className="mt-2 w-32 h-32 object-cover"
                    />
                  )
                )}
                <button
                  className="bg-red-500 h-8 mt-2 text-white p-1 rounded"
                  onClick={removeImage}
                >
                  {t("buttons.remove")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="float-right flex w-3/5 mr-8 gap-2 mt-4">
          <button
            type="button"
            className="block rounded-md bg-white px-3 py-2 text-center outline-blue-500 outline-[1px] outline text-sm font-semibold text-blue-600 hover:text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={onCancelClick}
          >
            {t("buttons.cancel")}
          </button>
          <button
            type="submit"
            className="block disabled:opacity-40 rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={onSubmit}
            disabled={loading}
          >
            {id === "new" ? t("buttons.create") : t("buttons.update")}
          </button>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};
