import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BASE_URL, generateFormattedId } from "../../constants";
import { Table, Tag, Tooltip, Timeline } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import AppContext from "../../AppContext";

const LogDetails = ({ timeSpan, catalogId, data, status }) => {
  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("YYYY-MM-DD hh:mm A");
  };

  const logsColumns = [
    {
      // width: 150,
      title: "Integration ID",
      dataIndex: "integrationId",
      key: "integrationId",
      hidden: true,
      render: (text, record) => (
        <span>
          {record.integrationId}
          <br />
          {/* <span className="text-xs text-gray-600">{record.correlationId}</span> */}
        </span>
      ),
    },
    {
      // width: 250,
      title: "Message",
      dataIndex: "message",
      key: "message",
      ellipsis: {
        showTitle: false,
      },
      render: (message) => (
        <Tooltip placement="topLeft" title={message}>
          {message}
        </Tooltip>
      ),
    },
    {
      // width: 200,
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (time) => <span>{formatDate(time)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Success" ? "green" : "red"}>{status}</Tag>
      ),
    },
  ];

  const columns = [
    {
      // width: 250,
      title: "Message",
      dataIndex: "message",
      key: "message",
      ellipsis: {
        showTitle: false,
      },
      render: (message) => (
        <Tooltip placement="topLeft" title={message}>
          {message}
        </Tooltip>
      ),
    },
    {
      // width: 200,
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (time) => <span>{formatDate(time)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Success" ? "green" : "red"}>{status}</Tag>
      ),
    },
  ];

  const [dataList, setDataList] = useState([]);
  const [integrationData, setIntegrationData] = useState([]);
  const { authCtx } = useContext(AppContext);

  const getTimeRange = (timeFrame) => {
    const today = moment();
    let startTime, endTime;

    if (timeFrame === "Today") {
      startTime = moment(today).startOf("day");
      endTime = moment(today).endOf("day");
    } else if (timeFrame === "Week") {
      startTime = moment(today).startOf("week");
      endTime = moment(startTime).add(1, "week").endOf("week");
    } else if (timeFrame === "Month") {
      startTime = moment(today).startOf("month");
      endTime = moment(startTime).endOf("month");
    }

    return { startTime, endTime };
  };

  const lookupIntegrationIds = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Lookup/Integrations`);
      // Map the response data to label and value structure
      const mappedIntegrationIds = response.data.map((integration) => ({
        label: generateFormattedId(integration.id).toLocaleUpperCase(),
        value: generateFormattedId(integration.id).toLocaleLowerCase(),
        name: integration.name,
      }));
      setIntegrationData(mappedIntegrationIds);
      return mappedIntegrationIds;
    } catch (error) {
      console.error("Error fetching integration:", error);
    }
  };

  useEffect(() => {
    lookupIntegrationIds();
  }, [catalogId]);

  useEffect(() => {
    console.log(catalogId);
    if (catalogId !== "") {
      fetchApis();
    } else {
      setDataList(data);
    }
  }, [catalogId, data, timeSpan]);

  const fetchApis = async () => {
    try {
      const integrationData = await lookupIntegrationIds();
      const { startTime, endTime } = getTimeRange(timeSpan);
      console.log(catalogId, "intId");
      const response = await axios.get(`${BASE_URL}/api/Logs/filtered`, {
        params: {
          integrationId: catalogId.toLocaleLowerCase(),
          startDate: startTime.toISOString(),
          endDate: endTime.toISOString(),
          status: status,
          companyId: authCtx.profile.companyId,
        },
      });
      const formattedData = response.data.data.map((group) => {
        const latestLog = group.logs[group.logs.length - 1];
        const integrationName =
          integrationData.find(
            (integration) => integration.value === latestLog.integrationId
          )?.name || "Unknown";
        return {
          key: group.correlationId,
          correlationId: group.correlationId,
          integrationId: latestLog.integrationId.toLocaleUpperCase(),
          integrationName: integrationName,
          message: latestLog.message,
          time: latestLog.time,
          status: latestLog.status,
          logs: group.logs,
        };
      });
      formattedData.sort((a, b) => new Date(b.time) - new Date(a.time));
      setDataList(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const expandedRowRender = (record) => (
    <Timeline mode="left" className="flex flex-col items-start">
      {record.logs.map((log, index) => (
        <Timeline.Item
          label={formatDate(log.time)}
          children={log.message}
          key={index}
          color={
            log.status === "Success"
              ? "green"
              : log.status === "Processing"
              ? "orange"
              : "red"
          }
        >
          {/* <p>{formatDate(log.time)}</p> */}
          <p className="w-[300px]">{log.message}</p>
          {index === 0 && log.source && (
            <p>
              {log.source?.name} To {log.destination?.name}
            </p>
          )}
        </Timeline.Item>
      ))}
    </Timeline>
  );
  return (
    <div className="mx-auto px-2 sm:px-4 lg:px-4 -mt-2 ">
      <div className="text-xl">
        {integrationData.find((i) => i.value.toLocaleUpperCase() === catalogId)
          ?.name || "Logs"}
      </div>

      <div className="flex lg:my-3 ">
        <Table
          className="w-full border rounded"
          columns={catalogId === "" ? logsColumns : columns}
          dataSource={dataList}
          expandable={{
            expandedRowRender: expandedRowRender,
            rowExpandable: (record) => record.logs.length > 0,
          }}
          pagination={false}
          scroll={{
            y: 500,
          }}
        />
      </div>
    </div>
  );
};

export default LogDetails;
