import React, { useState } from "react";
import { Select, Button } from "antd";
import { useTranslation } from "react-i18next";
import DestinationConnector from "./IntegrationFlow/EdgeForms/DestinationConnector";
import Markdown from "react-markdown";
import { SettingOutlined } from "@ant-design/icons";
import SqlDbConnector from "./SqlDbConnector";

const { Option } = Select;

const DestinationForm = ({
  handleEditDestination,
  destination,
  integration,
  setIntegration,
  applications,
  index,
}) => {
  const { t } = useTranslation();

  const handleDestinationChange = (value) => {
    const newDestinations = integration.destinations.map((dest, i) =>
      i === index
        ? {
            ...dest,
            connectivity: {
              ...dest.connectivity,
              type: value,
            },
          }
        : dest
    );
    console.log(newDestinations, "sde");
    if (value !== "application") {
      newDestinations[index] = {
        ...newDestinations[index],
        connectivity: {
          ...newDestinations[index].connectivity,
          mode: "",
        },
      };
    }

    setIntegration({ ...integration, destinations: newDestinations });
  };

  const handleApplicationTypeChange = (value) => {
    const newDestinations = integration.destinations.map((dest, i) =>
      i === index
        ? {
            ...dest,
            connectivity: {
              ...dest.connectivity,
              mode: value,
            },
          }
        : dest
    );
    setIntegration({ ...integration, destinations: newDestinations });
  };

  const handleModeChange = (value) => {
    const newDestinations = integration.destinations.map((dest, i) =>
      i === index
        ? {
            ...dest,
            connectivity: {
              ...dest.connectivity,
              type: value,
            },
          }
        : dest
    );
    setIntegration({ ...integration, destinations: newDestinations });
  };

  const handleChange = (fieldName, value) => {
    const newDestinations = integration.destinations.map((dest, i) =>
      i === index ? { ...dest, [fieldName]: value } : dest
    );
    setIntegration({ ...integration, destinations: newDestinations });
  };
  console.log(destination, "des");
  return (
    <div className="space-y-4">
      <SettingOutlined
        onClick={() => {
          handleEditDestination("");
        }}
      />
      <div>
        <label className="block text-gray-700">Destination</label>
        <Select
          value={destination?.connectivity?.type}
          onChange={handleDestinationChange}
          placeholder="Select Destination"
          className="w-full mt-1"
        >
          <Option value="application">Application</Option>
          <Option value="sqlDb">SQLDB Connector</Option>
          <Option value="ftp">FTP</Option>
          <Option value="notification">Notification</Option>
        </Select>
      </div>
      {destination?.connectivity?.type === "application" ? (
        <>
          <div className="flex flex-col text-lg font-semibold gap-1 mt-5">
            <label className="">{t(`form_labels.mode`)}</label>
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              value={destination?.connectivity?.type}
              onChange={handleModeChange}
              options={
                destination?.connectivity?.mode === "pull"
                  ? applications.filter((i) => i.ableToPull)
                  : applications
              }
            />
          </div>

          <div>
            <label className="block text-gray-700">Application Name</label>
            <input
              type="text"
              value={destination?.name}
              onChange={(e) => handleChange("name", e.target.value)}
              placeholder="Enter application name"
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            />
          </div>
          <DestinationConnector setIntegration={setIntegration} index={index} />
        </>
      ) : destination?.connectivity?.type === "sqlDb" ? (
        <>
          <SqlDbConnector mode={"destination"} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default DestinationForm;
