import React from "react";

const GlobalLoader = () => {
  return (
    <div class="bg-neutral-950 top-0 absolute z-[90000] bg-opacity-20 background-opacity-10 left-0 w-full min-h-screen flex justify-center items-center">
      <div
        class="w-20 h-20 rounded-full animate-spin
                    border-y-2 border-solid border-blue-500 border-t-transparent"
      ></div>
    </div>
  );
};

export default GlobalLoader;
