import "./App.css";
import "./markdown.css";
import Layout from "./Layout";
import { HashRouter, Route, Routes } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import IntegrationForm from "./components/Integrations/IntegrationFlow/IntegrationForm";
import UserList from "./components/Masters/User/UserList";
import UserForm from "./components/Masters/User/UserForm";
import AppList from "./components/Masters/Apps/AppList";
import { AppForm } from "./components/Masters/Apps/AppForm";
import "react-toastify/dist/ReactToastify.css";
import IntegrationList from "./components/Integrations/IntegrationList";
import LogsList from "./components/Monitoring/LogsList";
import AppContext from "./AppContext";
import Authlayout from "./components/Login/AuthLayout";
import Login from "./components/Login/Login";
import PasswordReset from "./components/Login/PasswordReset";
import ChangePassword from "./components/Login/ChangePassword";
import Monitoring from "./components/Monitoring/Monitoring";
import CompanyList from "./components/Masters/Company/CompanyList";
import { CompanyForm } from "./components/Masters/Company/CompanyForm";
import { gapi } from "gapi-script";
import msalInstance, { BASE_URL, Client_id } from "./constants";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import "./httpInterceptor";
import GlobalLoader from "./components/GlobalLoader";
import DataSimulator from "./components/DataSimulator";
import SignUp from "./components/Login/SignUp";
import Activation from "./components/Login/Activation";
import IntegrationWizard from "./components/Integrations/IntegrationWizard";
import FullscreenLoader from "./components/FullscreenLoader";
function App() {
  useEffect(() => {
    async function start() {
      try {
        await gapi.client.init({
          clientId: Client_id,
          scope: "",
        });
      } catch (error) {
        console.error("Error initializing Google client:", error);
      }
    }

    gapi.load("client:auth2", start);
    msalInstance.initialize();
  }, []);
  const { authCtx } = useContext(AppContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const [loading, setLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isNewUserChecked, setIsNewUserChecked] = useState(false);

  const showLoader = () => setLoading(true);
  const hideLoader = () => setLoading(false);

  axios.interceptors.request.use(
    (config) => {
      showLoader();
      return config;
    },
    (error) => {
      hideLoader();
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      hideLoader();
      return response;
    },
    (error) => {
      hideLoader();
      return Promise.reject(error);
    }
  );
  const checkIsNewUser = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/User/IsNewUser?id=${authCtx.profile.id}`
      );
      setIsNewUser(res.data);
    } catch (error) {
      console.error("Error checking if new user:", error);
    } finally {
      setIsNewUserChecked(true); // Hide loader after checking
    }
  };
  useEffect(() => {
    if (isLoggedIn && authCtx.profile.role !== 100) {
      checkIsNewUser();
    } else {
      setIsNewUserChecked(true);
    }
  }, [isLoggedIn, authCtx?.profile?.companyId]);
  return (
    <>
      {loading && <GlobalLoader />}
      {!isNewUserChecked && <FullscreenLoader />}
      {isLoggedIn && isNewUserChecked ? (
        <HashRouter>
          <Layout isNewUser={isNewUser}>
            <Routes>
              {!isNewUser ? (
                <>
                  <Route
                    path="/integrationList"
                    element={<IntegrationList />}
                  />
                  <Route
                    path="/integrationList/:type"
                    element={<IntegrationList />}
                  />
                  <Route path="/" element={<Monitoring />} />
                  <Route path="integration/:id" element={<IntegrationForm />} />
                  <Route
                    path="integrationWizard"
                    element={<IntegrationWizard />}
                  />
                  <Route path="userList" element={<UserList />} />
                  <Route path="userForm/:id" element={<UserForm />} />
                  <Route path="appList" element={<AppList />} />
                  <Route path="appForm/:id" element={<AppForm />} />
                  <Route
                    path="logList/:catalogId/:state"
                    element={<LogsList />}
                  />
                  <Route
                    path="logList/:catalogId/:state/:timeFrame"
                    element={<LogsList />}
                  />
                  <Route path="companyList" element={<CompanyList />} />
                  <Route path="companyForm/:id" element={<CompanyForm />} />
                  <Route path="dataSimulator" element={<DataSimulator />} />
                </>
              ) : (
                <Route path="/" element={<IntegrationWizard />} />
              )}
            </Routes>
          </Layout>
        </HashRouter>
      ) : (
        <HashRouter>
          <Authlayout>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="activate/:id" element={<Activation />} />
              <Route path="resetPassword" element={<PasswordReset />} />
              <Route
                path="changePassword/:token"
                element={<ChangePassword />}
              />
            </Routes>
          </Authlayout>
        </HashRouter>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </>
  );
}

export default App;
