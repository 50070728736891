import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../../constants";
import { useTranslation } from "react-i18next";

const Activation = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [data, setData] = useState({});
  const [resetPassword, setResetPassword] = useState({
    newPassword: "",
    confirmPassword: "",
    username: "",
  });

  const OnHandleChange = (e) => {
    setResetPassword((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (resetPassword.newPassword === resetPassword.confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [resetPassword]);

  const onClickLogo = () => {
    navigate("/");
  };
  useEffect(() => {
    getSignupDomument();
  }, [id]);

  const OnSubmit = async (e) => {
    if (checkUsername(resetPassword.username)) {
      setLoading(true);
      e.preventDefault();
      const newPass = {
        token: id,
        password: resetPassword.confirmPassword,
        username: resetPassword.username,
      };
      const res = await axios.post(
        `${BASE_URL}/api/Signup/ActivateAccount`,
        newPass
      );
      if (res.status === 200) {
        setLoading(false);
        toast.success("Account activated successfully");
        navigate(`/`);
      }
      setLoading(false);
    } else {
      toast.error("Username already exists");
    }
  };
  const getSignupDomument = async () => {
    let res = await axios.get(`${BASE_URL}/api/Signup/GetById?id=${id}`);
    setData(res.data);
    setResetPassword((prv) => ({
      ...prv,
      username: res.data.email.split("@")[0],
    }));
  };
  const checkUsername = async (username) => {
    try {
      let res = await axios.get(
        `${BASE_URL}/api/User/CheckUsername?username=${username}`
      );

      // If the username exists
      if (res.data === true) {
        return false;
      } else {
        // Username doesn't exist
        return true;
      }
    } catch (error) {
      console.error("Error checking username:", error);
      return false;
    }
  };

  return (
    <div className="h-full flex">
      <div className="sm:w-1/2 lg:w-2/3 sm:block hidden relative">
        <img
          className="loginImg h-screen  object-cover"
          src="loginPicture.jpg"
          alt="loginPicture"
        />
        <div className="absolute top-0 bg-black bg-opacity-50 h-full w-full">
          <div className="absolute top-1/4 lg:left-28 left-12 pr-5 text-white px-8 rounded-md py-6 flex flex-col gap-6">
            <label className="lg:text-4.5xl w-3/5 leading-10 text-4xl">
              {t("login_labels.login_message")}
            </label>
          </div>
        </div>
      </div>
      <form
        className="flex flex-col w-1/3 px-20 pt-24 relative mr-4"
        onSubmit={OnSubmit}
      >
        <label className="text-neutral-500 text-base font-medium mb-16 border-l-4 pl-2 border-l-orange-600">
          {t("login_labels.change_password_message")} Set a strong password and
          activate your account
        </label>
        <div className="text-sm font-medium text-white pb-2">
          {t("form_labels.username")}
        </div>
        <div className="text-sm text-center mb-5">
          <input
            name="username"
            type="username"
            placeholder={t("form_labels.username")}
            className="w-full h-12 pl-5 text-xs placeholder-neutral-400 bg-white font-medium rounded-lg border border-blue-800 ring-1 ring-sky-200"
            value={resetPassword?.username}
            // onBlur={() => checkUsername(resetPassword.username)}
            onChange={OnHandleChange}
          />
        </div>
        <div className="text-sm font-medium text-white pb-2">
          {t("login_labels.new_password")}
        </div>
        <div className="text-sm text-center mb-5">
          <input
            name="newPassword"
            type="password"
            placeholder={t("login_labels.new_password")}
            className="w-full h-12 pl-5 text-xs placeholder-neutral-400 bg-white font-medium rounded-lg border border-blue-800 ring-1 ring-sky-200"
            value={resetPassword.newPassword}
            onChange={OnHandleChange}
          />
        </div>
        <div className="flex flex-col">
          <label for="email" className="text-sm font-medium text-white pb-2">
            {t("login_labels.confirm_password")}
          </label>
          <input
            name="confirmPassword"
            type="password"
            placeholder={t("login_labels.confirm_password")}
            className={`${
              passwordMatch !== true
                ? "border-red-500 focus:ring-2 focus:ring-red-500 ring-1 ring-red-500"
                : " focus:ring-2 focus:ring-sky-500 ring-1 ring-sky-200"
            } w-full h-12 pl-5 text-xs placeholder-neutral-400 bg-white font-medium rounded-lg border border-blue-800`}
            value={resetPassword.confirmPassword}
            onChange={OnHandleChange}
          />
          {passwordMatch !== true && (
            <p className="text-red-600 text-xs mt-2">
              {t("login_labels.password_match_error")}
            </p>
          )}
          <button
            type="submit"
            className="bg-gradient-to-r flex justify-center items-center gap-2 from-red-800 disabled:opacity-40 to-orange-600 mt-4 text-white rounded-lg w-full h-14 font-semibold text-xs text-align-center"
            disabled={!passwordMatch || loading}
          >
            {t("buttons.submit")}
          </button>
        </div>
        <div className="flex justify-center gap-5 md:gap-8 items-center absolute bottom-8 right-1/2 transform -translate-y-1/2 translate-x-1/2">
          <label className="text-white text-xxs md:text-xs">
            {t("login_labels.powered_by")}
          </label>
          <img src="GamberaDynamicsLogo.svg" className="w-24 lg:w-28" />
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default Activation;
