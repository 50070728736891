import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../../constants";
import { useTranslation } from "react-i18next";

const PasswordReset = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validate, setValidate] = useState({
    username: false,
  });

  const onClickLogo = () => {
    navigate("/");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (username === "") {
      setValidate({
        username: true,
      });
    } else {
      setValidate({
        username: false,
      });
      setLoading(true);
      const res = await axios.get(
        `${BASE_URL}/api/Auth/ResetPassword?userName=${username}`
      );
      if (res.status === 200) {
        toast.success(
          "If Username matches with our account, You will recieve a link to reset the password in registered email"
        );
        setLoading(false);
      }
      setLoading(false);
      //navigate("/changePassword/1234")
    }
  };
  return (
    <div className="h-full flex">
      <div className="sm:w-1/2 lg:w-2/3 sm:block hidden relative">
        <img
          className="loginImg h-screen  object-cover"
          src="loginPicture.jpg"
          alt="loginPicture"
        />
        <div className="absolute top-0 bg-black bg-opacity-50 h-full w-full">
          <div className="absolute top-1/4 lg:left-28 left-12 pr-5 text-white px-8 rounded-md py-6 flex flex-col gap-6">
            <label className="lg:text-4.5xl w-3/5 leading-10 text-4xl">
              {t("login_labels.login_message")}
            </label>
          </div>
        </div>
      </div>
      <form className="sm:w-1/2 lg:w-1/3 w-full px-10 lg:px-20 pt-24 relative mr-1 lg:mr-4">
        <div className="text-center text-sm font-semibold text-white mb-2 mt-5">
          {t("login_labels.reset_your_password")}
        </div>
        <div className="text-sm text-center mb-10 text-gray-400">
          {t("login_labels.reset_password_message")}
        </div>
        <div className="pt-4">
          <div className="pt-1 flex flex-col gap-2">
            <label for="username" className="text-sm font-semibold text-white">
              {t("form_labels.username")}
            </label>
            <input
              type="email"
              name="username"
              placeholder={t("login_labels.username_placeholder")}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`${
                validate.username === true
                  ? "border-red-800  bg-red-550"
                  : "border-blue-800 bg-white "
              } w-full h-14 pl-5 text-xs placeholder-neutral-400 font-medium rounded-lg border`}
            ></input>
            {validate.username && (
              <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 28 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 7.10278V11.1028"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 15.1028H11.4582"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {t("login_labels.username_error")}
              </div>
            )}
            <div className="flex  justify-center items-center">
              <button
                type="submit"
                className="bg-gradient-to-r flex justify-center items-center gap-2 from-red-800 disabled:opacity-40 to-orange-600 mt-4 text-white rounded-lg w-full h-14 font-semibold text-xs text-align-center"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading && (
                  <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {t("buttons.submit")}
              </button>
            </div>
            <div className="flex mt-4 w-full justify-center items-center px-2">
              <button
                type="button"
                onClick={() => navigate("/")}
                className="text-xs text-center w-fit hover:underlinen text-blue-400 underline "
              >
                {t("login_labels.go_back")}
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-5 md:gap-8 items-center mt-24 lg:mt-48 lg:pt-3 bottom-8 right-1/2 transform -translate-y-1/2">
          <label className="text-white text-xxs md:text-xs">
            {t("login_labels.powered_by")}
          </label>
          <img
            src="GamberaDynamicsLogo.svg"
            className="w-24 lg:w-28"
            alt="gd-rms_logo"
          />
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default PasswordReset;
