import axios from "axios";
import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { BASE_URL } from "../../constants";
import { Progress } from "antd";
import Spline from "./LIneGraph";
import { useNavigate } from "react-router-dom";
// import SplineArea from "./AreaGraph";

const AdminPanel = ({ data, handlePointDoubleClick, timeStamp, t }) => {
  const navigate = useNavigate();
  //   const [data, setData] = useState(data);
  //   const lookupApi = async () => {
  //     try {
  //       const response = await axios.get(`${BASE_URL}/api/Lookup/dashboardInfo`);

  //       setData(response.data);
  //     } catch (error) {
  //       console.error("Error fetching integration:", error);
  //     }
  //   };
  //   useEffect(() => {
  //     lookupApi();
  //   }, []);

  return (
    <div className="flex flex-col h-full w-full px-5 bg-slate-100 pb-10 gap-5">
      <div class="flex flex-col m-2">
        <h1 class="text-2xl font-bold">{t("dashboard.title")}</h1>
        <p class="text-gray-600 font-semibold">
          {t("dashboard.welcome_message")}
        </p>
        {/* <p class="text-gray-600 w-2/5 text-sm">
          Here, we display the last 24 hours of log data and system health,
          among other relevant information.
        </p> */}
      </div>
      <div className="flex h-full justify-between flex-col">
        {data.logStatus && (
          <Spline
            handlePointDoubleClick={handlePointDoubleClick}
            data={data.logStatus}
            timeStamp={timeStamp}
          />
        )}
        <div class="flex w-full p-4 mb-4">
          <div className="flex w-full gap-6 justify-between">
            <div class="w-3/5 flex justify-between gap-2">
              <div
                onClick={() => navigate("/integrationList")}
                className="bg-slate-200 hover:bg-slate-100  hover:shadow-md h-full rounded-md w-full py-6 px-4 flex flex-col  gap-3"
              >
                <label className="text-slate-700 text-2xl font-bold leading-3">
                  {t("dashboard.catalog_section.title")}
                </label>
                <label className="text-slate-600 text-sm">
                  {t("dashboard.catalog_section.description")}
                </label>
                <label className="text-slate-700 text-5xl font-bold">
                  {data.totalCatalogCount}
                </label>
              </div>
              <div
                onClick={() => navigate("/integrationList/api")}
                className="bg-slate-200 hover:bg-slate-100 hover:shadow-md h-full rounded-md w-full py-6 px-4 flex flex-col  gap-3"
              >
                <label className="text-slate-700 text-2xl font-bold leading-3">
                  {t("dashboard.api_section.title")}
                </label>
                <label className="text-slate-600 text-sm">
                  {t("dashboard.api_section.description")}
                </label>
                <label className="text-slate-700 text-5xl font-bold">
                  {data.totalApiCount}
                </label>
              </div>
              <div
                onClick={() => navigate("/integrationList/notification")}
                className="bg-slate-200 hover:bg-slate-100 hover:shadow-md h-full rounded-md w-full py-6 px-4 flex flex-col  gap-3"
              >
                <label className="text-slate-700 text-2xl font-bold leading-3">
                  {t("dashboard.notifications_section.title")}
                </label>
                <label className="text-slate-600 text-sm">
                  {t("dashboard.notifications_section.description")}
                </label>
                <label className="text-slate-700 text-5xl font-bold">
                  {data.totalNotificationCount}
                </label>
              </div>
            </div>
            <div className="w-2/5 flex justify-between flex-col">
              <div className="flex gap-2">
                <div class="text-xl font-bold">
                  {t("dashboard.integrations.title")}
                </div>
                <div class="flex items-center justify-center px-2 bg-slate-200 rounded-md">
                  <div class="text-xl font-bold">
                    {data.uniqueCorrelationIds}
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-start justify-between mt-1">
                <div class="text-gray-500 text-sm flex justify-between w-full">
                  {t("dashboard.integrations.success_label")}
                  <span>{data.totalSuccessCount}</span>
                </div>
                <Progress
                  strokeColor={"green"}
                  showInfo={false}
                  percent={
                    (data.totalSuccessCount / data.uniqueCorrelationIds) * 100
                  }
                />
              </div>
              <div class="flex flex-col items-start justify-between mt-1">
                <div class="text-gray-500 text-sm flex justify-between w-full">
                  {t("dashboard.integrations.fail_label")}
                  <span>{data.totalFailCount}</span>
                </div>
                <Progress
                  showInfo={false}
                  strokeColor={"red"}
                  percent={
                    (data.totalFailCount / data.uniqueCorrelationIds) * 100
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
