/**
 * Sample for Spline series
 */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DateTime,
  SplineAreaSeries,
  AnnotationsDirective,
  AnnotationDirective,
  ChartAnnotation,
  Legend,
  Category,
  SplineSeries,
  Tooltip,
  ILoadedEventArgs,
  ChartTheme,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { Browser, EmitType } from "@syncfusion/ej2-base";

const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }
    #charts_Series_0_Point_2_Symbol {
        -webkit-animation: opac 1s ease-out infinite;
        animation: opac 1s ease-out infinite;
    }

    #charts_Series_2_Point_0_Symbol {
        -webkit-animation: opac 1s ease-out infinite;
        animation: opac 1s ease-in-out infinite;
    }

    @keyframes opac {
        0% {
            stroke-opacity: 1;
            stroke-width: 0px;
        }
        100% {
            stroke-opacity: 0;
            stroke-width: 10px;
        }
    }`;

const Spline = ({ data, handlePointDoubleClick, timeStamp }) => {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [largestCount, setLargestCount] = useState(0);
  useEffect(() => {
    if (data.length > 0) {
      console.log(data[0].time, "timedata");
      const firstDate = new Date(data[0].time);
      const lastDate = new Date(data[data.length - 1].time);
      setMinDate(firstDate);
      setMaxDate(lastDate);
      const newData1 = data.map((item) => ({
        x: new Date(item.time),
        y: item.successCount,
      }));
      const newData2 = data.map((item) => ({
        x: new Date(item.time),
        y: item.failCount,
      }));
      setData1(newData1);
      setData2(newData2);

      let maxCount = 0;
      data.forEach((item) => {
        if (item.successCount > maxCount) {
          maxCount = item.successCount;
        }
        if (item.failCount > maxCount) {
          maxCount = item.failCount;
        }
      });
      // Update the state with the maximum count
      setLargestCount(maxCount);
    } else {
      // Reset data if the new data is null or empty
      setData1([]);
      setData2([]);
      setMinDate(null);
      setMaxDate(null);
      setLargestCount(0);
    }
  }, [data]);

  return (
    <div className="control-panel">
      <style>{SAMPLE_CSS}</style>
      <div className="control-section">
        <ChartComponent
          id="charts"
          style={{ textAlign: "left" }}
          titleStyle={{ textAlign: "left" }}
          primaryXAxis={{
            valueType: "DateTime",
            majorGridLines: { width: 0 },
            intervalType: "Hours",
            edgeLabelPlacement: "Shift",
            labelFormat: timeStamp === "Today" ? "HH:mm" : "MMM dd, HH:mm", // Format for hours and minutes
            minimum: minDate, // Start date
            maximum: maxDate, // End date (24 hours later)
          }}
          primaryYAxis={{
            labelFormat: "{value}",
            lineStyle: { width: 0, color: "#21261e" },
            labelStyle: { color: "#21261e" },
            maximum: largestCount + 1,
            interval: largestCount > 5 ? Math.ceil(largestCount / 5) : 1,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
          }}
          width={"100%"}
          height="85%"
          legendSettings={{
            enableHighlight: true,
            position: "Top",
            alignment: "Near",
          }}
          chartArea={{ border: { width: 0 } }}
          title="Service Messages"
          tooltip={{ enable: true }}
          pointDoubleClick={handlePointDoubleClick}
        >
          <Inject
            services={[SplineAreaSeries, DateTime, Tooltip, Legend, Highlight]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={data1}
              xName="x"
              yName="y"
              name="Success"
              fill="#00c41a"
              marker={{
                visible: true,
                isFilled: true,
                height: 6,
                width: 6,
                shape: "Circle",
              }}
              opacity={0.5}
              type="SplineArea"
              width={2}
              border={{ width: 2 }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={data2}
              xName="x"
              yName="y"
              name="Fail"
              opacity={0.5}
              fill="#c41a00"
              marker={{
                visible: true,
                isFilled: true,
                height: 7,
                width: 7,
                shape: "Diamond",
              }}
              type="SplineArea"
              width={2}
              border={{ width: 2 }}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};
export default Spline;
