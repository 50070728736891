import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL, CleanKey, generateFormattedId } from "../constants";
import { UploadOutlined } from "@ant-design/icons";
import { Input, Select, Button, Table } from "antd";
import { toast } from "react-toastify";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import AppContext from "../AppContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const DataSimulator = () => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [timeSpan, setTimeSpan] = useState("Today");
  const { authCtx } = useContext(AppContext);
  useEffect(() => {
    fetchCompanies();
    loadData(authCtx.profile.companyId);
    setSelectedCompany(authCtx.profile.companyId);
  }, []);
  function getTimeFrameRange(timeFrame) {
    const today = moment();
    let startTime,
      endTime = moment(today);

    if (timeFrame === "Today") {
      startTime = moment(today).startOf("day");
    } else if (timeFrame === "Week") {
      startTime = moment(today).startOf("week");
    } else if (timeFrame === "Month") {
      startTime = moment(today).startOf("month");
    }

    return { startTime, endTime };
  }

  const handleSimulateClick = async () => {
    let isInvalidPercentage = false;
    const { startTime, endTime } = getTimeFrameRange(timeSpan);
    // Check if any integration has invalid percentages
    for (const integration of dataList) {
      // Skip validation if messagePerHour doesn't exist or is not greater than 0
      if (
        !integration.messagePerHour ||
        parseInt(integration.messagePerHour) <= 0
      ) {
        continue;
      }

      // Set status to "Pending" for non-skipped integrations during validation
      const updatedIntegration = { ...integration, status: "Pending" };
      const index = dataList.findIndex(
        (item) => item.id === updatedIntegration.id
      );
      dataList[index] = updatedIntegration;
      setDataList([...dataList]);
      setFilteredData([...dataList]);

      if (
        parseInt(integration.successPercentage) +
          parseInt(integration.failPercentage) !==
        100
      ) {
        isInvalidPercentage = true;
        toast.error(
          `Error: Integration ${integration.id} - Success and fail percentages should add up to 100.`
        );
        break; // Exit loop if an invalid integration is found
      }
    }

    if (!isInvalidPercentage) {
      // Example usage:
      // Call simulate API for each integration
      for (const integration of dataList) {
        // Skip validation and API call if messagePerHour is not greater than 0
        if (
          !integration.messagePerHour ||
          parseInt(integration.messagePerHour) <= 0
        ) {
          continue;
        }

        const updatedIntegration = { ...integration, status: "Processing" };
        const index = dataList.findIndex(
          (item) => item.id === updatedIntegration.id
        );
        dataList[index] = updatedIntegration;
        setDataList([...dataList]);
        setFilteredData([...dataList]);
        try {
          await axios.post(`${BASE_URL}/api/Logs/Simulate`, {
            integrationId: integration.id,
            messagesPerHour: parseInt(integration.messagePerHour),
            successPercentage: parseInt(integration.successPercentage),
            failurePercentage: parseInt(integration.failPercentage),
            startTime: startTime,
            endTime: endTime,
          });

          // Update status to success after simulation
          const updatedIntegration = { ...integration, status: "Done" };
          const index = dataList.findIndex(
            (item) => item.id === updatedIntegration.id
          );
          dataList[index] = updatedIntegration;
          setDataList([...dataList]);
          setFilteredData([...dataList]);
        } catch (error) {
          console.error("Error simulating integration:", error);
          //   toast.error(`Error simulating integration ${integration.id}`);
          const updatedIntegration = { ...integration, status: "Failed" };
          const index = dataList.findIndex(
            (item) => item.id === updatedIntegration.id
          );
          dataList[index] = updatedIntegration;
          setDataList([...dataList]);
          setFilteredData([...dataList]);
        }
      }
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Company`);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const loadData = async (companyId) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/api/IntegrationItems/GetActiveIntegrations?companyId=${companyId}`
      );
      setDataList(res.data);
      setFilteredData(res.data);
    } catch (error) {
      console.error("Error fetching integration items:", error);
    }
    setLoading(false);
  };

  const handleDropdownChange = (value) => {
    setSelectedCompany(value);
    loadData(value);
  };

  const handleCellChange = (value, id, dataIndex) => {
    const newData = dataList.map((item) =>
      item.id === id ? { ...item, [dataIndex]: value } : item
    );
    setDataList(newData);
    setFilteredData(newData);
  };
  const timeStamps = ["Today", "Week", "Month"];
  const columns = [
    {
      title: t("form_labels.integration_id"),
      dataIndex: "readableId",
      key: "readableId",
      // render: (id, __, index) => generateFormattedId(id),
    },
    {
      title: t("form_labels.integration_name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("form_labels.messagePerHour"),
      dataIndex: "messagePerHour",
      key: "messagePerHour",
      editable: true,
      render: (_, record) => {
        return {
          children: (
            <Input
              value={record.messagePerHour}
              onChange={(e) =>
                handleCellChange(e.target.value, record.id, "messagePerHour")
              }
            />
          ),
        };
      },
    },
    {
      title: t("form_labels.success_percentage"),
      dataIndex: "successPercentage",
      key: "successPercentage",
      editable: true,
      render: (_, record) => {
        return {
          children: (
            <Input
              value={record.successPercentage}
              onChange={(e) =>
                handleCellChange(e.target.value, record.id, "successPercentage")
              }
            />
          ),
        };
      },
    },
    {
      title: t("form_labels.fail_percentage"),
      dataIndex: "failPercentage",
      key: "failPercentage",
      editable: true,
      render: (_, record) => {
        return {
          children: (
            <Input
              value={record.failPercentage}
              onChange={(e) =>
                handleCellChange(e.target.value, record.id, "failPercentage")
              }
            />
          ),
        };
      },
    },
    {
      width: 100,
      title: t("form_labels.status"),
      dataIndex: "status",
      render: (status) => (
        <div
          className={`${
            status === "Failed" ? "bg-red-500" : "bg-green-500"
          } rounded-md px-2 py-1 w-fit text-xs text-white font-medium `}
        >
          {status === true ? (
            t("form_labels.active")
          ) : status === "Processing" ? (
            <LoadingOutlined className="text-base px-1" />
          ) : status === "Done" ? (
            <CheckOutlined className="text-base px-1" />
          ) : (
            status
          )}
        </div>
      ),
    },
  ];
  const handleTimeSpanChange = (newTimeSpan) => {
    setTimeSpan(newTimeSpan);
  };
  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-6">
      <div className="md:flex justify-between sm:items-center">
        <div className="md:flex w-full gap-4">
          <h1 className="md:text-xl w-fit font-semibold leading-6 text-gray-900">
            {t("pages.data_simulator")}
          </h1>
          <Select
            value={selectedCompany}
            onChange={handleDropdownChange}
            placeholder={t("form_labels.select_company")}
            style={{ width: 200 }}
          >
            {companies.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mt-4 flex gap-2 sm:ml-16 sm:mt-0 sm:flex-none">
          <div
            className="w-[300px] flex items-center rounded group border bg-white border-blue-500 list-none bg-blue-gray-50/60"
            role="list"
          >
            {timeStamps.map((item, index) => (
              <li
                key={item}
                className={`z-30 flex justify-center w-full h-full items-center text-center border-blue-500 ${
                  index === 0 ? "" : "border-l"
                } ${
                  timeSpan === item
                    ? "bg-blue-500 text-white"
                    : "hover:bg-cyan-100"
                }`}
                onClick={() => handleTimeSpanChange(item)}
                role="tab"
                aria-selected={timeSpan === item}
              >
                <div className="z-30 flex items-center justify-center w-full px-0 py-1 transition-all ease-in-out border-0 rounded-lg cursor-pointer bg-inherit">
                  <span className="ml-1 text-sm">
                    {t(`dashboard.tabs.${CleanKey(item)}`)}
                  </span>
                </div>
              </li>
            ))}
          </div>
          <Button
            onClick={() => handleSimulateClick()}
            type="primary"
            icon={<UploadOutlined />}
          >
            {t("buttons.simulate")}
          </Button>
        </div>
      </div>
      <div className="flex lg:my-3">
        <Table
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          pagination={false}
          loading={loading}
          className="w-full border"
          scroll={{
            y: "62vh",
          }}
        />
      </div>
    </div>
  );
};

export default DataSimulator;
