import axios from "axios";

const globalLoader = document.querySelector("#global-loader");

let activeRequests = 0;

const showLoader = () => {
  if (activeRequests === 0) {
    // globalLoader.style.display = "flex";
    document.body.classList.add("no-interaction");
  }
  activeRequests++;
};

const hideLoader = () => {
  activeRequests--;
  if (activeRequests === 0) {
    // globalLoader.style.display = "none";
    document.body.classList.remove("no-interaction");
  }
};

axios.interceptors.request.use(
  (config) => {
    showLoader();
    return config;
  },
  (error) => {
    hideLoader();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    hideLoader();
    return response;
  },
  (error) => {
    hideLoader();
    return Promise.reject(error);
  }
);
