import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Checkbox } from "antd";

const { Option } = Select;

const SqlDbConnector = ({ mode }) => {
  const [form] = Form.useForm();
  const [operationType, setOperationType] = useState(null);
  const [dbType, setDbType] = useState(null);

  const onFinish = (values) => {
    console.log("Form values: ", values);
    // Handle form submission
  };
  const validateSelectQuery = (_, value) => {
    if (!value) {
      return Promise.reject("Please input the SQL query!");
    }

    const trimmedQuery = value.trim().toLowerCase();
    if (!trimmedQuery.startsWith("select")) {
      return Promise.reject("The query must be a SELECT statement!");
    }

    return Promise.resolve();
  };
  useEffect(() => {
    if (mode === "destination") {
      setOperationType("push");
    }
  }, [mode]);

  return (
    <div className="mx-auto bg-white rounded-lg">
      <Form
        form={form}
        name="sql-db-connector"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="dbType"
          label="Database Type"
          rules={[
            {
              required: true,
              message: "Please select the database type!",
            },
          ]}
        >
          <Select
            placeholder="Select database type"
            onChange={(value) => setDbType(value)}
          >
            <Option value="postgresql">PostgreSQL</Option>
            <Option value="sqlAzure">SQL Azure</Option>
            <Option value="mysql">MySQL</Option>
            <Option value="sqlServer">SQL Server</Option>
          </Select>
        </Form.Item>

        {mode !== "destination" && (
          <Form.Item
            name="operationType"
            label="Operation Type"
            rules={[
              { required: true, message: "Please select the operation type!" },
            ]}
          >
            <Select
              placeholder="Select operation type"
              onChange={(value) => setOperationType(value)}
            >
              <Option value="pull">Pull</Option>
              <Option value="push">Push</Option>
            </Select>
          </Form.Item>
        )}

        {operationType && dbType && (
          <>
            {operationType === "push" && (
              <>
                <Form.Item
                  name="connectionString"
                  label="Connection String"
                  rules={[
                    {
                      required: true,
                      message: "Please input the connection string!",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your connection string here" />
                </Form.Item>

                <Form.Item
                  name="tableName"
                  label="Table Name"
                  rules={[
                    { required: true, message: "Please input the table name!" },
                  ]}
                >
                  <Input placeholder="Table Name" />
                </Form.Item>
              </>
            )}

            {operationType === "pull" && (
              <>
                <Form.Item
                  name="sqlQuery"
                  label="SQL Query"
                  rules={[{ validator: validateSelectQuery }]}
                  validateTrigger="onBlur"
                >
                  <Input.TextArea
                    placeholder="Enter your SQL query here"
                    autoSize={{ minRows: 4, maxRows: 10 }}
                    onBlur={() => form.validateFields(["sqlQuery"])}
                  />
                </Form.Item>
                <Form.Item
                  name="connectionString"
                  label="Connection String"
                  rules={[
                    {
                      required: true,
                      message: "Please input the connection string!",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your connection string here" />
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

export default SqlDbConnector;
