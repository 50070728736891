import React, { useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import SourceConnector from "./IntegrationFlow/EdgeForms/SourceConnector";
import Markdown from "react-markdown";
import SqlDbConnector from "./SqlDbConnector";

const { Option } = Select;

const SourceForm = ({
  config,
  setConfig,
  integration,
  setIntegration,
  applications,
}) => {
  const { t } = useTranslation();
  const documentation = `
  # Hermes Integration Platform API Documentation
  
  ## Example Request
  
  **URL:** \`https://gd-dev-apim.azure-api.net/sendMessage?subscription-key=YOUR_SUBSCRIPTION_KEY&integration-id=YOUR_INTEGRATION_ID\`
  
  **HTTP Method:** \`POST\`
  
  **Content-Type:** \`application/json\`
  
  **Payload:**
  
  \`\`\`json
  {
      "name": "Json Doe",
      "age": 20,
      "phone": "9856452136"
  }
  \`\`\`
  `;

  const handleSourceChange = (value) => {
    let newState = {
      ...integration,
      source: {
        ...integration.source,
        connectivity: {
          ...integration?.source?.connectivity,
          type: value,
        },
      },
    };

    if (value !== "application") {
      newState = {
        ...newState,
        source: {
          ...newState.source,
          connectivity: {
            ...newState.source.connectivity,
            mode: "",
          },
        },
      };
    }

    // Set the new state
    setIntegration(newState);
  };

  const handleApplicationTypeChange = (value) => {
    setIntegration({
      ...integration,
      source: {
        ...integration.source,
        connectivity: {
          ...integration?.source?.connectivity,
          mode: value,
        },
      },
    });
  };
  const handleModeChange = (value) => {
    setIntegration({
      ...integration,
      source: {
        ...integration.source,
        connectivity: {
          ...integration?.source?.connectivity,
          type: value,
        },
      },
    });
  };
  const handleChange = (fieldName, value) => {
    setIntegration({
      ...integration,
      source: { ...integration.source, [fieldName]: value },
    });
  };
  const ApiDocumentation = () => (
    <div className="markdown-body">
      <Markdown>{documentation}</Markdown>
    </div>
  );
  return (
    <div className="space-y-4 ">
      <div>
        <label className="block text-gray-700">Source</label>
        <Select
          value={integration?.source?.connectivity?.type}
          onChange={handleSourceChange}
          placeholder="Select source"
          className="w-full mt-1"
        >
          <Option value="application">Application</Option>
          <Option value="sqlDb">SQLDB Connector</Option>
          <Option value="ftp">FTP</Option>
          <Option value="email">Email</Option>
        </Select>
      </div>
      {integration?.source?.connectivity?.type === "application" ? (
        <>
          <div>
            <label className="block text-gray-700">Application Type </label>
            <Select
              value={integration?.source?.connectivity?.mode}
              onChange={handleApplicationTypeChange}
              placeholder="Select application type"
              className="w-full mt-1"
            >
              <Option value="push">Push</Option>
              <Option value="pull">Pull</Option>
            </Select>
          </div>
          <div className="flex flex-col text-lg font-semibold gap-1 mt-5">
            <label className="">{t(`form_labels.mode`)}</label>
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              value={integration?.source?.connectivity?.type}
              onChange={handleModeChange}
              options={
                integration?.source?.connectivity?.mode === "pull"
                  ? applications.filter((i) => i.ableToPull)
                  : applications
              }
            />
          </div>
          {integration?.source?.connectivity?.type === "application" && (
            <div>
              <label className="block text-gray-700">Application Name</label>
              <input
                type="text"
                value={integration?.source?.name}
                onChange={(e) => handleChange("name", e.target.value)}
                placeholder="Enter application name"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
          )}
          {integration?.source?.connectivity?.mode === "push" && (
            <ApiDocumentation />
          )}
          {integration?.source?.connectivity?.mode === "pull" &&
            integration?.source?.connectivity?.type === "application" && (
              <>
                <SourceConnector
                  formData={config}
                  setFormData={setConfig}
                  integration={integration}
                  onSave={setIntegration}
                />
              </>
            )}
        </>
      ) : integration?.source?.connectivity?.type === "sqlDb" ? (
        <>
          <SqlDbConnector />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SourceForm;
