import { Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CustomTimePicker = ({ alertConfig, setAlertConfig }) => {
  const { t } = useTranslation();
  const handleDaysChange = (value) => {
    setAlertConfig((prevAlertConfig) => ({
      ...prevAlertConfig,
      alertData: {
        ...prevAlertConfig.alertData,
        interval: {
          ...prevAlertConfig.alertData.interval,
          days: value,
        },
      },
    }));
  };

  const handleHoursChange = (value) => {
    setAlertConfig((prevAlertConfig) => ({
      ...prevAlertConfig,
      alertData: {
        ...prevAlertConfig.alertData,
        interval: {
          ...prevAlertConfig.alertData.interval,
          hours: value,
        },
      },
    }));
  };

  const handleMinutesChange = (value) => {
    setAlertConfig((prevAlertConfig) => ({
      ...prevAlertConfig,
      alertData: {
        ...prevAlertConfig.alertData,
        interval: {
          ...prevAlertConfig.alertData.interval,
          minutes: value,
        },
      },
    }));
  };
  return (
    <div className="custom-time-picker w-4/5 space-y-1 items-center">
      <div className="grid gap-2 text-xs grid-cols-3">
        <div className="flex flex-col">
          <label htmlFor="days">{t(`form_labels.days`)}</label>
          <Select
            className="col-span-2"
            value={alertConfig.alertData.interval.days}
            onChange={handleDaysChange}
          >
            {[...Array(101).keys()].map((day) => (
              <Option key={day} value={day}>
                {day}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="days">{t(`form_labels.hours`)}</label>
          <Select
            className="col-span-2"
            value={alertConfig.alertData.interval.hours}
            onChange={handleHoursChange}
          >
            {[...Array(24).keys()].map((hour) => (
              <Option key={hour} value={hour}>
                {hour}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="days">{t(`form_labels.minutes`)}</label>
          <Select
            className="col-span-2"
            value={alertConfig.alertData.interval.minutes}
            onChange={handleMinutesChange}
          >
            {[...Array(60).keys()].map((minute) => (
              <Option key={minute} value={minute}>
                {minute}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default CustomTimePicker;
