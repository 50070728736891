import { Button, Input, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import PayloadMapper from "./PayloadMapper";

const SourceConnector = ({ index, setIntegration }) => {
  const { t } = useTranslation();

  // Local state for managing form data
  const [formData, setFormData] = useState({
    httpVerb: "get", // Default httpVerb
    apiUrl: "", // Initial empty apiUrl
    headers: [], // Initial empty array for headers
  });

  const [mapperPopup, setMapperPopup] = useState(false);

  const options = [
    {
      value: "get",
      label: (
        <span>
          <CloudDownloadOutlined /> GET
        </span>
      ),
    },
    {
      value: "post",
      label: (
        <span>
          <CloudUploadOutlined /> POST
        </span>
      ),
    },
  ];

  const handleVerbChange = (verb) => {
    setFormData((prevData) => ({ ...prevData, httpVerb: verb }));
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onHeaderChange = (e, i) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      headers: prevData.headers.map((header, index) =>
        index === i ? { ...header, [name]: value } : header
      ),
    }));
  };

  const handleSave = () => {
    setIntegration((integration) => ({
      ...integration,
      destinations: integration.destinations.map((dest, i) =>
        i === index
          ? {
              ...dest,
              connectivity: {
                ...dest?.connectivity,
                configurations: JSON.stringify(formData),
              },
            }
          : dest
      ),
    }));
  };

  return (
    <div
      onMouseLeave={handleSave}
      className="text-sm flex flex-col justify-between relative font-medium bg-white rounded border-neutral-200 border px-7 gap-2 my-5 h-3/4 shadow-sm"
    >
      <Modal
        title="PayLoad Mapper"
        centered
        style={{ width: "max-content", paddingBottom: "0px" }}
        visible={mapperPopup}
        onCancel={() => setMapperPopup(false)}
        width={800}
        footer={null}
      >
        <PayloadMapper
          setForm={setFormData}
          setPopup={setMapperPopup}
          form={formData}
        />
      </Modal>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between border border-neutral-200 rounded-md p-3 text-sm font-medium mt-5">
          <label>{t("form_labels.payload_mapper")}</label>
          <button
            onClick={() => setMapperPopup(true)}
            className="text-blue-600"
          >
            {t("buttons.edit")}
          </button>
        </div>
        <div className="flex flex-col text-sm font-medium gap-1 mt-5">
          <label>{t("form_labels.http_verb")}</label>
          <div className="flex gap-2">
            {options.map((option) => (
              <Button
                key={option.value}
                className={`
                  ${
                    formData.httpVerb === option.value &&
                    "bg-blue-500 text-white"
                  }
                  ${formData.httpVerb !== option.value && "bg-white text-black"}
                `}
                onClick={() => handleVerbChange(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </div>
        </div>
        <div className="flex flex-col text-sm font-medium gap-1 mt-5">
          <label>{t("form_labels.api_url")}</label>
          <Input
            style={{ width: "100%" }}
            placeholder={t("form_labels.api_url_placeholder")}
            name="apiUrl"
            value={formData.apiUrl}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <div className="w-full text-sm flex justify-between gap-4">
            <label className="text-sm font-medium">
              {t("form_labels.headers")}
            </label>
            <button
              onClick={() =>
                setFormData((prevData) => ({
                  ...prevData,
                  headers: [...prevData.headers, { key: "", value: "" }],
                }))
              }
              className="bg-green-300 border border-green-600 rounded h-6 w-6 flex justify-center items-center text-xl text-center"
            >
              +
            </button>
          </div>
          <div className="flex flex-col gap-2 border py-1 border-slate-200 px-1 rounded overflow-auto relative h-40">
            <div className="w-full text-xs flex gap-2 text-white sticky top-0 z-50 rounded">
              <div className="w-2/5 text-center bg-neutral-600 py-1 rounded-tl rounded-bl">
                {t("form_labels.key")}
              </div>
              <div className="w-1/2 text-center bg-neutral-600 py-1">
                {t("form_labels.value")}
              </div>
              <div className="w-[10%] px-1 text-center bg-neutral-600 py-1 rounded-tr rounded-br">
                {t("form_labels.action")}
              </div>
            </div>
            {formData.headers.map((header, i) => (
              <div key={i} className="w-full flex text-xs gap-2">
                <Input
                  placeholder={t("form_labels.key")}
                  className="w-2/5"
                  value={header.key}
                  name="key"
                  onChange={(e) => onHeaderChange(e, i)}
                />
                <Input
                  className="w-1/2"
                  placeholder={t("form_labels.value")}
                  value={header.value}
                  name="value"
                  onChange={(e) => onHeaderChange(e, i)}
                />
                <Button
                  className="w-[10%]"
                  onClick={() =>
                    setFormData((prevData) => ({
                      ...prevData,
                      headers: prevData.headers.filter(
                        (_, index) => index !== i
                      ),
                    }))
                  }
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceConnector;
